/**
 *  VARIABLES
 *
 *  Configure library by adjusting this file
 *
 */

$brown10: #C49A6C;
$brown20: #8B5E3C;

$bg-light: #F8F0E5;
$bg-light-filter: brightness(0) saturate(100%) invert(95%) sepia(9%) saturate(627%) hue-rotate(319deg) brightness(104%) contrast(94%);
$bg-dark: #EADBC8;
$bg-dark-filter: brightness(0) saturate(100%) invert(90%) sepia(32%) saturate(208%) hue-rotate(327deg) brightness(96%) contrast(92%);
$primary: #DAC0A3;
$primary-filter: brightness(0) saturate(100%) invert(95%) sepia(7%) saturate(2051%) hue-rotate(316deg) brightness(93%) contrast(82%);

$text-primary: #4B5777;
$text-secondary: #B5BFD1;
$text-third: #F7E7EA;
$text-primary-filter: brightness(0) saturate(100%) invert(14%) sepia(28%) saturate(3237%) hue-rotate(195deg) brightness(94%) contrast(97%);
$text-white: #FFFFFF;