#basic-page {
  .container-header{
    width: 100%;
    position: relative;
    margin: 0 auto;

    .container-image{
      position: relative;
      background-color: grey;
      height: 100vh;
      width: 100%;
      margin: 0 auto;
    }
    .image-header{
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      background-color: rgb(0, 0, 0);
      opacity: 0.5;
    }
    .container-text{
      position: absolute;
      display: inline-block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      top: 10%;
      left: 0;
      right: 0;
      color: white;

      strong{
          font-weight: bold;
      }

      .quotes-wrapper {
        margin: 100px 0;

        h1 {
          font-size: 55px;
          font-family: "PinyonScript";
        }
      }
    }
    .text-header{
      width: 80%;
      margin: 0 auto;
    }
    .interval-date{
      color: white;
      background: none;
      border: 2px white;
      border-style: solid;
      border-radius: 10px;
      padding-top: 14px;
      width: 55px;
      height: 55px;
    }
    .interval-date:hover{
      cursor: pointer;
      border: 2px solid $brown30 !important;
      background-color: $brown30 !important;
    }
    .button-open{
      color: white;
      width: 200px;
      margin: 0 auto;
      background: none;
      border: 2px white;
      border-style: solid;
      border-radius: 10px;
      margin-top: 5vh;
      padding: 10px;

      &:hover{
          cursor: pointer;
          border: 2px solid $brown30 !important;
          background-color: $brown30 !important;
      }
    }
    .container-date{
      position: absolute;
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 19vh;
      width: 80%;
      color: white;
    }
    .container-date-countdown{
      position: absolute;
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 5vh;
      width: 80%;
      color: white;
    }
  }

  .container {
    margin: 0 auto;
    text-align: center;
  }

  .banner-wrapper {
    height: 100vh;
    padding: 100px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header-title {
    text-align: center;
    margin: 50px;
  }

  .container-content{
    margin: 3rem auto;
    width: 80%;
    text-align: center;
  }

  .container-content-music{
    margin: 3rem auto;
    width: 50%;
    text-align: center;
  }

  .container-quote{
    margin: 0 auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
  }

  .banner{
    position: relative;
    background-color: grey;
    height: 0;
    padding-bottom: 80%;
    width: 100%;
    margin: 0 auto;
  }

  .image{
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .image-content-container{
    display: flex;

    .image-content{
      position: relative;
      background-color: grey;
      height: 0;
      padding-bottom: 50%;
      width: 100%;
      margin: 0 auto;
    }
  }

  .couple{
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-left: 30%;
    margin-right: 30%;

    h1{
      font-family: "PinyonScript";
    }
    .sign{
      padding-top: 5%;
    }
    .bride-groom{
      text-align: center;
    }
  }

  .cotainer-video{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background-color: grey;
  }

  .video{
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .btn-register{
    margin: 0 auto;

    a.btn-register-style{
      color: #ffffff !important;
      background: $brown30;
      border: 2px solid $brown30;
      border-radius: 10px;
      padding: 10px;

      &:hover{
          font-weight: 600;
          color: $brown15 !important;
          background: #ffffff;
          border: 2px solid $brown15;
      }
    }
  }

  .container-gift{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
    margin: 0 auto;

    p{
        color: $brown30;
    }
    .btn-gift{
      width: 100%;
      margin: 0 auto;

      button.btn-gift-style{
        color: #ffffff;
        background: $brown30;
        border: 2px solid $brown30;
        border-radius: 10px;
        padding: 10px;
        width: 250px;
        max-width: 80%;
        
        &:hover{
          cursor: pointer;
          color: $brown30;
          border: 2px solid $brown30;
          background-color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  // Modal
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    z-index: 3;
    color: $brown30;

    .btn-popup{
      color: white;
      width: 200px;
      margin: 0 auto;
      background: $brown30;
      border: 2px solid white;
      border-radius: 10px;
      margin-top: 5vh;
      padding: 10px;

      &:hover{
        cursor: pointer;
        color: $brown30;
        border: 2px solid $brown30 !important;
        background-color: #fff !important;
      }
    }

    .close {
      color: #fff;
      float: right;
      font-size: 50px;
      font-weight: bold;
      padding: 20px;
    }
    .close:hover, .close:focus {
      color: $brown30;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .popup_inner {
    position: absolute;
    left: 10%;
    right: 10%;
    top: 10%;
    bottom: 10%;
    margin: auto;
    background: white;
    z-index: 3;
    overflow-y: scroll;
    text-align: center;
    padding-top: 15px;
    border-radius: 10px;

    .popup-img{
      background-color: $brown30;
      width: 100%;
      height: 50vh;

      .popup-img-content{
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .music{
    position:fixed;
    width:50px;
    height:50px;
    bottom:50px;
    right:20px;
    background-color:#FFEDED;
    border-radius:30px;
    text-align:center;
    box-shadow: 2px 2px 3px $brown30;
    z-index: 1;
  }

  .my-music{
    color: $brown30;
    margin-top:18px;
  }

  #footer-section-ultimate {
    background-color: $brown30;
    color: white;
    padding-bottom: 5vh;
    padding-top: 2rem;

    .container-logo-image{
        .img{
            object-fit: contain;
            width: 80px;
            margin-left: 8px;
        }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 2556px){
  #basic-page {
    overflow-x: hidden;

    #about img {
      margin: 50px 0;
    }

    h1 {
      word-break: break-word;
      font-size: 2rem;
    }
    h2 {
      word-break: break-word;
      font-size: 1.75rem;
    }

    h3 {
      word-break: break-word;
      font-size: 1.5rem;
    }
  }
}

@media only screen and (max-width: 480px){
  #basic-page {
    overflow-x: hidden;

    h1 {
      word-break: break-word;
      font-size: 2.5rem;
    }

    .header-title {
      width: unset;

      h1 {
        word-break: unset;
        font-size: 1.8rem;
      }
    }

    h2 {
      word-break: break-word;
      font-size: 1.25rem;
    }

    h3 {
      word-break: break-word;
      font-size: 2rem;
    }
  }
}

@media (max-width: 768px) {
  #basic-page {
    .couple {
      display: block;
      justify-content: space-between;
      margin: 3rem auto;
      width: 80%;

      .sign{
        padding-top: unset;
        text-align: center;
      }
    }

    .image-content-container {
      display: block;

      .image-content{
        position: relative;
        background-color: grey;
        height: 100vh;
        width: 100%;
        margin: 0 auto;
      }
    }

    .container-content-music {
      width: 80%;
    }
  }
}
