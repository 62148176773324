.countdown-container {
  .show-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    font-size: 24px;

    .countdown {
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}