#banner-section {
  margin-bottom: 100px;

  .banner-wrapper {
    background: url("../images/banner-transparant.png") no-repeat center;
    background-size: cover;
    min-height: 100vh;

    margin-bottom: 50px;

    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        object-fit: contain;
        width: 250px;
      }
    }

    .text-wrapper {
      text-align: center;
      font-size: 50px;

      .bold {
        font-size: 80px;
        font-weight: 800;
      }
    }
  }
}