.light-brown-txt{
  color: #C49A6C;
}

.dark-brown-txt{
  color: #8B5E3C;
}

.orange-bg {
  background-color: #E0C06B;
}

.white-bg {
  background-color: white;
}

.white-txt{
  color: white;
}

.light-brown-bg{
  color: white;
  background-color: #C49A6C;
}

.txt-size-05rem{
  font-size: 0.5rem;
}
.txt-size-075rem{
  font-size: 0.75rem;
}
.txt-size-1rem{
  font-size: 1rem;
}
.txt-size-15rem {
  font-size: 1.5rem;
}
.txt-size-2rem{
  font-size: 2rem;
}
.txt-size-3rem{
  font-size: 3rem;
}
.txt-size-35rem{
  font-size: 3.5rem;
}
.txt-size-4rem{
  font-size: 4rem;
}
.txt-size-5rem{
  font-size: 5rem;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.dark-brown-bg {
  color: white;
  background-color: #8B5E3C;
}

#header-section {
  color: black;
}

#footer-section {
  background-color: $third-color;
  padding: 50px;
  color: white;
}

#footer-section-premium {
  background-color: #8B5E3C;
  color: white;
  padding-bottom: 15vh;
}

.default-section-wrapper {
  padding: 50px 0;

  &.type-first {
    padding-top: 0;
  }

  &.type-last {
    padding-bottom: 0;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-space-around {
  justify-content: space-around;
}

.position-relative {
  position: relative;
}

.neon-text {
  color: #fff;
  text-shadow:
    /* White glow */
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
      /* Green glow */
    0 0 42px transparent,
    0 0 82px transparent,
    0 0 92px transparent,
    0 0 102px transparent,
    0 0 151px transparent;

  &.dark-brown {
    text-shadow:
      /* White glow */
      0 0 7px rgb(239, 156, 99),
      0 0 10px rgb(239, 156, 99),
      0 0 21px rgb(239, 156, 99),
        /* Green glow */
      0 0 42px transparent,
      0 0 82px transparent,
      0 0 92px transparent,
      0 0 102px transparent,
      0 0 151px transparent;
  }
}