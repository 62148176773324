.button-primary {
  border-radius: 10px;
  padding: 10px;
  width: 250px;
  max-width: 80%;
  margin: auto;

  &:hover{
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
  }

  &.type-brown {
    color: #ffffff ;
    background: $brown20;
    border: 2px solid $brown20;

    &:hover {
      color: $brown20;
      background-color: #fff;
      border: 2px solid $brown20;
    }
  }

  &.type-blue {
    color: #ffffff;
    background: $blue40;
    border: 2px solid $blue40;

    &:hover{
      color: $blue40;
      background: #ffffff;
      border: 2px solid $blue40;
    }
  }

  &.type-purple {
    color: #ffffff;
    background: $purple60;
    border: 2px solid $purple60;

    &:hover{
      color: $purple60;
      background: #ffffff;
      border: 2px solid $purple60;
    }
  }
}

.button-company {
  align-items: center;
  background-color: #ef9c63;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  padding: 0 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;

  &:hover, &:focus {
    background-color: #360e54;
    color: #ffffff;
  }

  &:active {
    background: #360e54;
    color: white;
  }

  &:disabled {
    cursor: not-allowed;
    background: black;
    color: grey;
  }
}