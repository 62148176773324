/**
 *  TYPOGRAPHY
 */

html, body {
  background-color: $body-bg;
  color: $text-body;
  width: 100%;
  // height: 100vh;
  font-size: 14px;
  // @extend .secondary-font;
  overflow: unset;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin: 0;
}

p {
  margin-bottom: 10px;
}

a {
  color: $text-body;
  text-decoration: none !important;

  &:hover {
    color: $text-body;
  }

  &:focus {
    color: $text-body;
  }
}

img {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $heading-weight;
}
