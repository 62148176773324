#feature-section {
  .default-title {
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    letter-spacing: 7px;
    background: $secondary-color;
    padding: 8px 15px;
    border-radius: 4px;

    margin-bottom: 100px;
  }

  .slick-slider-wrapper {
    .item-wrapper {
      padding: 15px;
      max-width: 500px;

      .item {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        min-height: 325px;

        cursor: pointer;
        -webkit-box-shadow: 1px 3px 10px -4px #777;
        -moz-box-shadow: 1px 3px 10px -4px #777;
        box-shadow: 1px 3px 10px -4px #777;

        .img {
          object-fit: contain;
          width: 85px;
        }
      }
    }
  }
}