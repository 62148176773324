#demo-page {
  .btn {
    background-color: #f68181;
    border-color: #f68181;
  }

  .banner-section {
    position: relative;
    width: 100%;
    z-index: 4;

    &:after {
      background-color: rgba(23,23,38,.6);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
    }

    .banner-img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
      position: absolute;
    }

    .text-wrapper {
      min-height: 80vh;
      padding-top: 10rem;
      padding-bottom: 10rem;
      z-index: 2;
      text-align: center;
      align-items: center;
      position: relative;

      .name {
        color: white;
        margin-bottom: 1.5rem;
        font-size: 5.5rem;
        font-weight: 200;
        line-height: 1.2;
      }

      .date {
        font-weight: 300;
        color: white;
        margin-bottom: 2rem;
        font-size: 1.375rem;
      }
    }
  }

  .date-section {
    .img {
      width: 100%;
      object-fit: cover;
      border-radius: 60px;
    }
  }

  .story-section {
    .img {
      width: 100%;
      object-fit: cover;
      border-radius: 60px;
    }
  }
}