@media (max-width: 1400px) {
  #not-found-page {
    .lamp {
      zoom: .5;
    }

    .error__content {
      top: 55%;
    }

    .message__title {
      font-size: 3.5rem;
    }
  }
}

@media (max-width: 950px) {
  html, body {
    height: unset !important;
  }

  #not-found-page {
    .lamp__wrap {
      max-height: 100vh;
      overflow: hidden;
      max-width: 100vw;
    }

    .error__social {
      bottom: 30px;
      top: auto;
      transform: none;
      width: 100%;
      position: fixed;
      left: 0;
    }

    .e-social__icon {
      display: inline-block;
      padding-right: 30px;
    }

    .e-social__icon:last-child {
      padding-right: 0;
    }

    .e-social__icon {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 900px) {
  #not-found-page {
    .message__title {
      font-size: 34px;

    }
    .error__content {
      top: 55%;
    }
  }
}

@media only screen and (max-width: 720px) {
  .default-title {
    font-size: 30px;
  }

  .default-section-wrapper {
    padding: 25px 0;
  }

  #banner-section {
    .banner-wrapper {
      background-size: 100% !important;
      min-height: unset !important;

      display: flex;
      justify-content: center;
      align-items: center;

      .logo-wrapper {
        .logo {}
      }

      .text-wrapper {
        font-size: 35px !important;

        .bold {
          font-size: 55px !important;
        }
      }
    }
  }

  #reason-section {
    .reason-wrapper {
      .item {
        margin: unset !important;
        padding: unset !important;
      }
    }
  }

  #product-section {
    background: url("../images/banner-product.png") no-repeat bottom !important;
    background-size: auto !important;

    .default-section-wrapper {
      padding-bottom: 0 !important;
    }

    .product-wrapper {
      padding: 25px 0 !important;
    }
  }

  #not-found-page {
    max-height: 100vh;

    .error__content {
      position: static;
      margin: 0 auto;
      transform: none;
      padding-top: 300px;
    }

    .error {
      padding-top: 0;
      padding-bottom: 100px;
      height: 100vh;
    }
  }

  /* START COMPONENT */
  #two-sided-images-component {
    margin: 50px 25px;

    .body-wrapper {
      display: unset;

      .item {
        .text-wrapper {
          position: unset;
        }
      }
    }
  }

  #data-and-place-circle-component {
    .title {
      letter-spacing: unset;
    }
  }

  #footer-second-component {
    .body-wrapper {
      .img {
        object-fit: fill;
        height: 150px;
        width: 150px;
      }
    }
  }
  /* END COMPONENT */
}

@media (max-width: 650px) {
  #not-found-page {
    .message__title {
      font-size: 36px;
      padding-bottom: 20px;
    }

    .message__text {
      font-size: 16px;
      line-height: 2;
      padding-right: 20px;
      padding-left: 20px;
    }

    .lamp {
      zoom: .6;
    }

    .error__content {
      padding-top: 180px;
    }
  }
}


@media (max-width: 480px) {
  #not-found-page {
    .message__title {
      font-size: 30px;
    }

    .message__text {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 15px;
    }

    .error__nav {
      margin-top: 20px;
    }
  }

  #our-story-section {
    .our-story-slider-wrapper {
      margin-bottom: 35px;

      .slick-arrow {
        &.slick-prev {
          left: 0 !important;
        }

        &.slick-next {
          right: 0 !important;
        }
      }

      .slick-dots {
        position: unset !important;
        bottom: unset !important;
        margin-top: 10px;
        margin-bottom: unset !important;
      }
    }
  }
}
