#reason-section {
  .default-title {
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    letter-spacing: 7px;
    background: $main-color;
    padding: 8px 15px;
    border-radius: 4px;
  }

  .reason-wrapper {
    .item {
      background: transparent;
      border: transparent;
      border-radius: 12px;

      width: 100%;
      min-height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      padding: 15px;

      transition: all ease-in-out 0.5s;

      &:hover {
        cursor: pointer;
        -webkit-box-shadow: 1px 3px 10px -4px #777;
        -moz-box-shadow: 1px 3px 10px -4px #777;
        box-shadow: 1px 3px 10px -4px #777;
      }

      .img {
        object-fit: contain;
        width: 85px;
      }
    }
  }
}