#our-story-section {
  &.type-grey-30{
    border: 5px double $grey30 !important;
    .our-story-slider-wrapper {
      .slick-dots {
        li {
          button::before {
            color: $grey30;
          }
        }
      }
    }
  }

  border: 5px double $blue50;
  border-radius: 10px;
  padding: 6px;
  width: 80%;

  margin: auto;
  margin-bottom: 25px;

  .title{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .our-story-slider-wrapper {

    .slick-arrow {
      // display: none !important;
      z-index: 5;

      &.slick-prev {
        transform: rotate(90deg);
        left: 100px;

        &:before {
          font-size: 16px;
          content: '❤';
          color: $blue50;
        }
      }

      &.slick-next {
        transform: rotate(-90deg);
        right: 100px;

        &:before {
          font-size: 16px;
          content: '❤';
          color: $blue50;
        }
      }
    }

    .slick-dots {
      position: unset;
      bottom: unset;
      margin-top: 2px;
      margin-bottom: 15px;

      li {
        button::before {
          font-size: 20px;
          content: '❤';
          color: $blue50;
        }
      }

      .slick-active {
        button::before {
          color: $blue50;
        }
      }
    }

    .item-wrapper {
      .item {
        text-align: center;
        padding: 20px 10%;

        .title {
          font-size: 18px;
          margin-bottom: 12px;
          font-weight: bold;
          color: $purpletext;
        }

        .description {
          color: $blue50;
          max-width: 285px;
          margin: auto;
          font-size: 16px;
        }
      }
    }
  }
}