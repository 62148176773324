.loading-wrapper {
  overflow: hidden;
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 50;
  height: 100vh;
  width: 100%;

  .backdrop {
    background: black;
    opacity: 60%;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 5;
  }

  .loading-gif {
    object-fit: contain;
    height: 100px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}