/**
 *  VARIABLES
 *
 *  Configure library by adjusting this file
 *
 */

$body-bg:               #ffffff;
$bg-grey:               #eaeaea;

$main-color:            #E54B13;
$secondary-color:       #2D368C;
$third-color:           #A0A5D8;

// Text colors
$text-body:             #000000;
$text-grey:             #686868;

// MEDIA QUERIES BREAKPOINTS
$screen-xs:             0px;
$screen-sm:             480px;
$screen-md:             768px;
$screen-lg:             1024px;
$screen-sp-lg:          1440px;


$heading-weight:        300;

// custom color
$white:             #ffffff;
$grey:              #ddd;
$bg-grey-darker:    #707070;

$brown10:           #C49A6C;
$brown20:           #8B5E3C;

//abel-dien
$purple10:          #bb7dc0;//terang
$purple20:          #9e4ea3;//gelep

//olin
$blue40:            #03506F;
$cream:             #FFE3D8;

$blue50:            #0A043C;
$grey10:            #BBBBBB;

//abel-dien1
$purple30:          #F6C6EA;//terang
$purple40:          #C490E4;//gelep
$purpletext:        #665261;

//abel-dien2
$purple50:          #C9B6E4;//terang
$purple60:          #BE9FE1;//gelep

//Ginia
$pink10: #FFE2E2;
$pink20: #FFC7C7;
$grey20: #F6F6F6;
$grey25: #AAAAAA;
$grey30: #505050;

// Jonathan
$pink11: #FFEDED;
$brown15: #B3541E;
$brown30: #362222;

// Elvia
$purple70:          #B1B2FF; //dark
$cream10:           #EEF1FF; //light
$blue30:           #AAC4FF; //blue soft

//Jingga
$blue60:    #1C3879; //dark
$blue10:    #607EAA; //soft
$grey11:    #EAE3D2; //grey

//Leo
$leo1: #F5E8C7; //cream11
$leo2: #483434; //brown11
$leo3: #6B4F4F; //brown12
$leo4: #FFFFFF;

//Dennis
$dennisBlue: #0F2C59;
$dennisMilkBrown: #DAC0A3;
$dennisGrey: #EADBC8;
$dennisCream: #F8F0E5;