#product-section {
  padding-bottom: 300px;
  background: url("../images/banner-product.png") no-repeat center;
  background-size: cover;

  .default-title {
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    letter-spacing: 7px;
    background: $main-color;
    padding: 8px 15px;
    border-radius: 4px;
  }

  .product-wrapper {
    padding: 100px 0;

    .img-wrapper {
      position: relative;

      &:before {
        content: "";
        background-color: $secondary-color;
        width: 55%;
        height: 80%;

        position: absolute;
        z-index: 0;
        right: 5%;
        top: -10%;
        border-radius: 10px;
      }

      .img {
        position: relative;
        object-fit: contain;
        height: 450px;
      }
    }

    .text-wrapper {
      text-align: left;
    }
  }
}