.company-invitation-container {
  &.type-admin {
    padding: 16px;
    max-width: unset !important;
  }
}
#company-invitation-container {
  min-height: 100vh;
  overflow-y: auto;
  max-width: 800px;
  margin: auto;

  h3 {
    font-family: "Lato-Bold";
    letter-spacing: 2px;
    font-size: 20px;
    word-break: break-word;
  }

  h5 {
    font-family: "Roboto-Regular";
    color: white;
  }

  .fs-14 {
    font-size: 14px;
  }

  .word-spacing-2 {
    word-spacing: 2px;
  }

  .header-transition-wrapper {
    background: rgb(94,23,103);
    background: linear-gradient(180deg, rgba(94,23,103,1) 45%, rgba(36,9,76,1) 100%);
    width: 100%;
    height: 35px;
    margin-top: -5px;
  }

  .title-wrapper {
    // padding: 16px;
    text-align: center;
    // margin: 60px 0;
    margin-bottom: 60px;

    .description {
      text-align: center;
    }

    .event-for {
      font-size: 72px;
      letter-spacing: 6px;
    }
  }

  .border-color-wrapper {
    background: radial-gradient(circle at 100% 100%, #24094C 0, #24094C 2px, transparent 2px) 0% 0%/6px 6px no-repeat,
    radial-gradient(circle at 0 100%, #24094C 0, #24094C 2px, transparent 2px) 100% 0%/6px 6px no-repeat,
    radial-gradient(circle at 100% 0, #24094C 0, #24094C 2px, transparent 2px) 0% 100%/6px 6px no-repeat,
    radial-gradient(circle at 0 0, #24094C 0, #24094C 2px, transparent 2px) 100% 100%/6px 6px no-repeat,
    linear-gradient(#24094C, #24094C) 50% 50%/calc(100% - 8px) calc(100% - 12px) no-repeat,
    linear-gradient(#24094C, #24094C) 50% 50%/calc(100% - 12px) calc(100% - 8px) no-repeat,
    linear-gradient(289deg, transparent 0%, #d11ca4 100%),
    linear-gradient(90deg, transparent 0%, #48abe0 100%);
    border-radius: 6px;
    box-sizing: content-box;

    padding: 16px;
    margin: 16px;
    text-align: center;
  }

  .additional-information-wrapper {
    padding: 16px;
    margin: 16px;
    text-align: center;
  }

  .event-information-wrapper {
    position: relative;

    .meteor-img {
      position: absolute;
      z-index: 0;
      height: 65vh;
      width: 100vw;
    }

    .frame-top{
      position: absolute;
      z-index: 0;
      height: 50px;
      width: 50px;
      left: 10px;
      top: -10px;
    }
    .frame-btm {
      position: absolute;
      z-index: 0;
      right: 5px;
      height: 50px;
      width: 50px;
      bottom: 0;
    }

    .event-date-wrapper {
      text-align: center;
      padding: 16px;

      .container-date-countdown {
        margin-top: 24px;
      }

      .icon {
        object-fit: contain;
        height: 20px;
        width: 20px;
        margin: 0 16px;
      }
    }

    .information-wrapper, .dresscode-wrapper {
      padding: 16px;
      text-align: center;
    }

    .information-wrapper {
      .img-no-need {
        object-fit: contain;
        height: 75px;
        width: 75px;
      }

      .img-arrow {
        object-fit: contain;
        width: 45px;
      }

      .img-tiktok{
        transition: transform .2s;
      }

      .img-tiktok:hover{
        transform: scale(1.1)
      }
    }
  }

  .form-wrapper {
    padding: 16px;
    margin-top: 100px;

    .title {
      text-align: center;
    }

    .member-only-wrapper {
      position: absolute;
      right: 24px;
      top: -45px;

      .member-only-img {
        object-fit: contain;
        height: 85px;
        width: 85px;
      }
    }
  }

  .company-admin-invitation-wrapper {
    padding: 16px;
  }

  .icon-wrapper {
    .icon {
      object-fit: contain;
      height: 70px;
      width: 70px;
      position: absolute;
    }

    .top-left {
      top: 22%;
      left: 16px;
    }
    .top-right {
      top: 22%;
      right: 16px;
    }
    .bottom-left {
      bottom: 20px;
      left: 16px;
    }
    .bottom-right {
      bottom: 20px;
      right: 16px;
    }
  }

  .more-information-wrapper {
    .website {
      position: absolute;
      top: 45%;
      left: 10px;
      font-size: 23px;
    }
  }

  .logo-tiktok {
    img {
      object-fit: contain;
      height: 45px;
      width: 45px;
      margin-right: 12px;
    }
  }
}

@media (min-width: 950px) {
  #company-invitation-container {
    .icon-wrapper {
      .icon {
        height: 140px !important;
        width: 140px !important;
      }

      .top-left {
        top: 0 !important;
        left: 10% !important;
      }
      .top-right {
        top: 0 !important;
        right: 10% !important;;
      }
      .bottom-left {
        bottom: 0 !important;
      }
      .bottom-right {
        bottom: 0 !important;
      }
    }

    .more-information-wrapper {
      .website {
        left: 24px !important;
        font-size: 52px !important;
      }
    }

    .event-information-wrapper {
      .information-wrapper {
        .img-no-need {
          height: 185px !important;
          width: 185px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  #company-invitation-container {
    .title-wrapper {
      // margin: 25px 0 !important;
      margin-bottom: 25px !important;

      .event-for {
        font-size: 48px !important;
      }
    }

    .form-wrapper {
      margin-top: 35px !important;
    }
  }
}