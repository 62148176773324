@font-face{
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
  url('../../fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face{
  font-family: "Montserrat-MediumItalic";
  src: local("Montserrat-MediumItalic"),
  url('../../fonts/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face{
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
  url('../../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face{
  font-family: "AppleGaramond";
  src: local("AppleGaramond"),
  url('../../fonts/AppleGaramond.ttf') format('truetype');
}

@font-face{
  font-family: "AppleGaramond-Bold";
  src: local("AppleGaramond-Bold"),
  url('../../fonts/AppleGaramond-Bold.ttf') format('truetype');
}

@font-face{
  font-family: "AppleGaramond-BoldItalic";
  src: local("AppleGaramond-BoldItalic"),
  url('../../fonts/AppleGaramond-BoldItalic.ttf') format('truetype');
}

@font-face{
  font-family: "Artilla";
  src: local("Artilla"),
  url('../../fonts/Artilla.ttf') format('truetype');
}

@font-face{
  font-family: "Scripalt";
  src: local("Scripalt"),
  url('../../fonts/Scripalt.ttf');
}

@font-face{
  font-family: "Scriptin";
  src: local("Scriptin"),
  url('../../fonts/Scriptin.ttf');
}

@font-face{
  font-family: "PinyonScript";
  src: local("PinyonScript"),
  url('../../fonts/PinyonScript-Regular.ttf');
}

@font-face {
  font-family: "TanseekModern";
  src: local("TanseekModern"),
  url("../../fonts/tanseek-modern-pro-arabic-book.ttf");
}

@font-face {
  font-family: "StylishCalligraphyDemo";
  src: local("StylishCalligraphyDemo"),
  url("../../fonts/StylishCalligraphyDemo-XPZZ.ttf");
}

@font-face {
  font-family: "SpicyRice-Regular";
  src: local("SpicyRice-Regular"),
  url("../../fonts/SpicyRice-Regular.ttf");
}

@font-face {
  font-family: "Cristik";
  src: local("Cristik"),
  url("../../fonts/Cristik.ttf");
}

@font-face {
  font-family: "Regular-Brush";
  src: local("Regular-Brush"),
  url("../../fonts/Regular-Brush.otf");
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"),
  url("../../fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
  url("../../fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
  url("../../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: 'Rosarivo-Italic';
  src: local("Rosarivo-Italic"),
  url("../../fonts/Rosarivo-Italic.ttf")
}

@font-face {
  font-family: 'Rosarivo-Regular';
  src: local("Rosarivo-Regular"),
    url("../../fonts/Rosarivo-Regular.ttf")
}
.font-r-i{
  font-family: "Rosarivo-Italic";
}
.font-r-i {
  font-family: "Rosarivo-Regular";
}

.font-ar{
  font-family: "Artilla" !important;
}
.font-a{
  font-family: "AppleGaramond";
}
.font-a-b{
  font-family: "AppleGaramond-Bold";
}
.font-a-b-i{
  font-family: "AppleGaramond-BoldItalic";
}
.font-m-r{
  font-family: "Montserrat-Regular";
}
.font-m-b{
  font-family: "Montserrat-Bold";
}
.font-m-m{
  font-family: "Montserrat-MediumItalic";
}
.font-s-alt {
  font-family: "Scripalt";
}
.font-s-tin {
  font-family: "Scriptin";
}

.font-StylishCalligraphyDemo {
  font-family: "StylishCalligraphyDemo";
}
.font-SpicyRice-Regular {
  font-family: "SpicyRice-Regular";
}

.font-cristik {
  font-family: "Cristik";
}
.font-regular-brush {
  font-family: "Regular-Brush";
}

