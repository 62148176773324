@import '_colors.scss';
@import '_fonts.scss';

// Header: font-m-b
// Sub-Header: font-m-r / font-m-m
// Text: font-r-i

#ultimate-page-arwin {
    .container-body {
        //Ultimate Page
        background: $text-primary;

        .content {
            max-width: 888px;
            margin: 0 auto;
            background-color: $text-primary;
        }

        .container-header {
            width: 100%;
            position: relative;
            margin: 0 auto;
            color: $text-primary !important;

            .container-image {
                position: relative;
                background-color: grey;
                height: 100vh;
                width: 100%;
                margin: 0 auto;
            }

            .image-header {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                background-color: rgb(0, 0, 0);
                opacity: 1;
            }

            .container-text {
                position: absolute;
                display: inline-block;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                top: 36% !important;
                left: 0;
                right: 0;
                color: $text-primary;

                strong {
                    font-weight: bold;
                }
            }

            .text-header {
                width: 80%;
                margin: 0 auto;
            }

            

            .button-open {
                color: $text-white;
                width: 200px;
                margin: 0 auto;
                background: none;
                border: 2px $text-white;
                border-style: solid;
                border-radius: 10px;
                margin-top: 5vh;
                padding: 10px;

                &:hover {
                    cursor: pointer;
                    border: 2px solid $text-primary !important;
                    background-color: $text-primary !important;
                }
            }
        }

        .container-header-count {
            width: 100%;
            position: relative;
            margin: 0 auto;
            color: $text-primary !important;

            .container-image {
                position: relative;
                background-color: grey;
                height: auto;
                width: 100%;
                margin: 0 auto;
            }

            .container-date-countdown {
                position: absolute;
                display: flex;
                justify-content: space-evenly;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                top: 4vh;
                left: 0;
                right: 0;
                width: 80%;
                color: $text-primary;
            }

            .interval-date {
                color: $text-primary;
                background: $text-secondary;
                border: 2px $text-secondary;
                border-style: solid;
                border-radius: 10px;
                padding-top: 6px;
                width: 50px;
                height: 60px;
            }

            .interval-date:hover {
                cursor: pointer;
                color: $text-secondary;
                border: 2px solid $text-primary !important;
                background-color: $text-primary !important;
            }

            .container-date {
                position: absolute;
                display: block;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                bottom: 19vh;
                width: 80%;
                color: $text-white;
            }
        }

        .container-banners {
            width: 100%;
            position: relative;
            margin: 0 auto;
            padding-bottom: unset !important;
            color: $text-primary;
            background-color: $text-secondary;

            .container-image-banner {
                position: relative;
                height: 70px;
                top: -60px;
                width: 100%;
                margin: 0 auto;
            }

            .image-banner {
                position: absolute;
                object-fit: fill;
                width: 100%;
                height: 100%;
            }

            .container-content-banner {
                width: 100%;
                // height: 422px !important;
                margin: 0 auto;
                padding-top: 55px;
                color: $text-primary;
                background-color: $text-secondary;
            }

            .container-icon-banner {
                position: relative;
                width: 100%;
                height: 100px;
                margin: 0 auto;
            }

            .icon-banner {
                position: absolute;
                object-fit: contain;
                width: 50%;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
            }

            .text-banner {
                text-align: center;
                margin: 0 auto;
                width: 80%;
            }

            .container-image2-banner {
                position: relative;
                height: 70px;
                bottom: -25px;
                width: 100%;
                margin: 0 auto;
                z-index: 1;

                filter: $primary-filter;
            }
            
            .container-image3-banner {
                position: relative;
                // top: 100%;
                // transform: translateY(-100%);
                // height: 300px;
                width: 100%;
                margin: 0 auto;
            }
            .image3-banner {
                // position: absolute;
                object-fit: contain;
                width: 100%;
                // height: 100%;
            }

        }

        .container-bride {
            width: 100%;
            margin: 0 auto;

            

            .container-icon-bride {
                position: relative;
                height: 0;
                padding-bottom: 50%;
                width: 100%;
                margin: 0 auto;
            }

            .icon-bride {
                position: absolute;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            .title-bride {
                text-align: center;
                padding-bottom: 40px;
                color: $text-primary;
            }

            .container-bride-name {
                display: block;
                text-align: center;
                background-color: $bg-light !important;
                color: $text-primary;
                padding: 30px 0 30px 0;
                border-radius: 10px;
            }

            .container-bride-photo {
                position: relative;
                // height: 574px;
                width: 100%;
                // border-radius: 50%;
                margin: 0 auto;
                background-color: $text-secondary;

                .container-groom-name-inside{
                    text-align: right !important;
                    position: absolute;
                    bottom: 88px;
                    right: 40px;
                    color: $text-white;
                }

                .container-bride-name-inside {
                    text-align: left !important;
                    position: absolute;
                    bottom: 108px;
                    left: 40px;
                    color: $text-primary;
                }
            }

            .bride-photo {
                // position: absolute;
                object-fit: contain;
                width: 100%;
                // height: 100%;
                // border-radius: 50%;
            }
        }

        .container-event {
            text-align: center;
            background-color: $text-secondary;
            color: $text-primary;

            .container-image3-event {
                position: relative;
                // top: 100%;
                transform: rotate(180deg);
                // height: 300px;
                width: 100%;
                margin: 0 auto;

                .image3-banner {
                    // position: absolute;
                    object-fit: contain;
                    width: 100%;
                    // height: 100%;
                }
            }

            p {
                color: $text-primary;
                margin-bottom: unset;
                margin-top: revert;
            }

            .content-event {
                width: 80%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                // border: 5px double $text-primary;
                // border-radius: 10px;
                padding: 10px 10px 0px 10px;

                .line {
                    width: 50%;
                    padding: 1px;
                    border-top: 1px solid $text-primary;
                    border-bottom: 1px solid $text-primary;
                }

                .border-line{
                    border: 2px solid $text-primary;
                    border-radius: 8px;
                    padding: 8px;
                }

                svg {
                    font-size: 30px;
                }

                p {
                    text-align: center;
                }
            }

            .add-google {
                width: 100%;
                margin: 0 auto;
            }

            .add-google a {
                color: $text-primary;
                border: 2px solid $text-primary;
                border-radius: 10px;
                padding: 10px;


                &:hover {
                    cursor: pointer;
                    color: $text-white !important;
                    border: 2px solid $text-primary !important;
                    background-color: $text-primary !important;
                    transform: scale(1.1);
                    text-decoration: none;

                }
            }
        }

        .container-gallery {
            width: 100%;
            text-align: center;
            color: $text-primary;
            background-color: $text-third;
            // pointer-events: none;

            .column {
                -ms-flex: 25%;
                /* IE10 */
                flex: 25%;
                max-width: 25%;
                padding: 0 4px;
            }
        
            .column img {
                margin-top: 8px;
                vertical-align: middle;
                width: 100%;
            }

            p {
                color: $text-primary;
            }

            .lg-custom-thumbnails {
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(29, 5vw);
                grid-gap: 15px;

                a {
                    background: #ddd;
                }

                .gallery-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: opacity 0.5s;
                    -webkit-transition: opacity 0.5s;
                    opacity: 1;
                }

                .gallery-img:hover {
                    opacity: 0.5;
                }

                .gallery-item--1 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 1;
                    grid-row-end: 5;
                }

                .gallery-item--2 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 1;
                    grid-row-end: 9;
                }

                .gallery-item--3 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 5;
                    grid-row-end: 15;
                }

                .gallery-item--4 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 9;
                    grid-row-end: 19;
                }

                .gallery-item--5 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 19;
                    grid-row-end: 30;
                }

                .gallery-item--6 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 15;
                    grid-row-end: 20;
                }

                .gallery-item--7 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 20;
                    grid-row-end: 30;
                }

                .gallery-item--8 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 23;
                    grid-row-end: 33;
                }

                .gallery-item--9 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 30;
                    grid-row-end: 35;
                }

                .gallery-item--10 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 33;
                    grid-row-end: 43;
                }

                .gallery-item--10-el {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 33;
                    grid-row-end: 40;
                }

                .gallery-item--11 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 35;
                    grid-row-end: 43;
                }

                .gallery-item--11-el {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 35;
                    grid-row-end: 40;
                }
            }
        }

        .container-slider-photo {
            position: relative;
            // min-height: 500px;
            width: 100%;
            margin: 0 auto;
            padding-top: 15px;
            background-color: $text-third;

            .slide-photo {
                // position: absolute;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .container-quotes {
            width: 100%;
            position: relative;
            margin: 0 auto;
            color: $text-primary;
            padding-top: 48px;
            text-align: center;
            background-color: $text-third;

            .container-image-quotes {
                position: relative;
                height: 70px;
                // top: -60px;
                width: 100%;
                margin: 0 auto;

                img {
                    filter: $bg-light-filter;
                }
            }

            .image-quotes {
                position: absolute;
                object-fit: fill;
                width: 100%;
                height: 100%;
            }

            .container-text {
                position: relative;
                // top: -50px;
                width: 52%;
                margin: 0 auto;
                text-align: center;
                color: $text-primary;
            }
        }

        .container-timeline {
            width: 80%;
            margin: 0 auto;

            .title-timeline {
                text-align: center;
                color: $text-primary;
            }

            .ant-timeline-item-head-blue {
                color: $text-primary;
                border-color: $text-primary;
            }

            .container-timeline-content {
                color: $text-primary;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rgba(0, 0, 0, .05);
                box-shadow: 0 2px 8px -2px rgba(0, 0, 0, .30);
            }
        }

        .container-gift {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            margin: 0 auto;
            color: $text-primary;
            background-color: $text-white;

            p {
                color: $text-primary;
                margin-bottom: unset;
            }

            .btn-gift {
                width: 100%;
                margin: 0 auto;

                button.btn-gift-style {
                    color: $text-primary;
                    background: $text-secondary;
                    border: 2px solid $text-secondary;
                    border-radius: 10px;
                    padding: 10px;
                    width: 250px;
                    max-width: 80%;

                    &:hover {
                        cursor: pointer;
                        color: $text-white !important;
                        border: 2px solid $text-secondary !important;
                        background-color: $text-secondary !important;
                        text-decoration: none;

                    }
                }
            }
        }

        .container-wishes {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: $text-primary;
            background-color: $text-white;

            p {
                color: $text-primary;
                margin-bottom: unset;
            }

            form {
                width: 80%;
            }

            input,
            textArea {
                width: 100%;
                padding: 10px;
                margin-bottom: 15px;
                border-radius: 5px;
                border: 1px solid $bg-light;
                box-shadow: 0 1px 1px $bg-dark;
                color: $text-third;
                background-color: $text-secondary;
            }

            input:focus-visible,
            textArea:focus-visible {
                outline: none;
            }

            .add-wishes {
                width: 100%;
                margin: 0 auto;

                button.btn-wishes-style {
                    color: $text-primary;
                    background: $text-secondary;
                    border: 2px solid $text-secondary;
                    border-radius: 10px;
                    padding: 10px;
                    width: 250px;
                    max-width: 80%;

                    &:hover {
                        cursor: pointer;
                        color: $text-primary;
                        border: 2px solid $text-primary !important;
                        background-color: $text-primary !important;
                        text-decoration: none;
                    }
                }
            }

            .container-wishes-list {
                width: 80%;
                height: 50vh;
                overflow-y: scroll;
                padding-right: 15px;
                color: $text-primary !important;

                div {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: start;
                    padding-bottom: 15px;
                    

                    .content {
                        width: 100%;
                        padding: 15px 4px;
                        background-color: $text-third;
                        border-radius: 15px;
                        margin: unset;

                        p {
                            color: $text-primary !important;
                        }

                        .name {
                            font-weight: bold;
                        }
                    }

                    div {
                        display: flex;
                        flex-wrap: wrap;
                        text-align: start;
                        flex-direction: column;

                        svg {
                            margin-top: 15px;
                            margin-right: 15px;
                            color: $text-primary;
                        }
                    }
                }
            }
        }

        .container-text-video {
            width: 80%;
            margin: 0 auto;
            text-align: center;
            color: $text-primary;
        }

        .container-video {
            position: relative;
            width: 100%;
            height: 0;
            margin: 0 auto;
            padding-bottom: 50%;
            background-color: $bg-dark;
        }

        .video {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .container-thankyou {
            width: 100%;
            margin: 0 auto;
            

            .container-image-thankyou {
                position: relative;
                height: -webkit-fill-available;
                width: 100%;
                margin: 0 auto;
            

                img {
                    // filter: $primary-filter;
                }

                .text-inside-image{
                    position: absolute;
                    top: 32px;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .container-text-thankyou {
                        width: 280px;
                        text-align: center;
                        margin: 0 auto;
                        color: $text-primary;
                    }

                    .image-thankyou-inside{
                        position: absolute;
                        object-fit: contain;
                        width: 100%;
                        // height: 648px;
                    }
                }
            }

            .image-thankyou {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .image-logo{
                position: absolute;
                object-fit: contain;
                width: 20%;
            }
        }

        .menu-sticky {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 2;

            .container-menu {
                display: flex;
                justify-content: space-around;
                background: $text-primary;
                align-items: center;
                // max-width: 888px;
                margin: 0 auto;

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 5px;
                    cursor: pointer;
                }

                div img {
                    object-fit: cover;
                    width: 50px;
                    height: auto;
                    transition: transform 0.5s;
                    -webkit-transition: transform 0.5s;
                    transform: scale(1);
                }

                div img:hover {
                    transform: scale(1.1);
                }
            }
        }

        // Modal
        .popup {
            position: fixed;
            width: 100%;
            height: 100%;
            // top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 3;
            color: $text-primary;

            .btn-popup {
                color: $text-primary;
                width: 200px;
                margin: 0 auto;
                background: $text-third !important;
                border: 2px solid $text-primary;
                border-radius: 10px;
                margin-top: 5vh;
                padding: 10px;

                &:hover {
                    cursor: pointer;
                    color: $text-white;
                    border: 2px solid $text-primary !important;
                    background-color: $text-primary !important;
                }
            }

            .close {
                color: #fff;
                float: right;
                font-size: 50px;
                font-weight: bold;
                padding: 20px;
            }

            .close:hover,
            .close:focus {
                color: $primary;
                text-decoration: none;
                cursor: pointer;
            }
        }

        .popup_inner {
            padding: 0 16px !important;
            position: absolute;
            left: 10%;
            right: 10%;
            top: 10%;
            bottom: 10%;
            margin: auto;
            background: $text-third !important;
            z-index: 3;
            overflow-y: scroll;
            text-align: center;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .popup-img {
                background-color: $text-primary;
                width: 100%;
                height: 50vh;

                .popup-img-content {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .music {
            position: fixed;
            width: 50px;
            height: 50px;
            bottom: 90px;
            right: 20px;
            background-color: $text-primary;
            border-radius: 30px;
            text-align: center;
            box-shadow: 2px 2px 3px #999;
            z-index: 1;
        }

        .my-music {
            color: #FFF;
            margin-top: 18px;
        }

        #footer-section-arwin {
            color: $text-primary;
            background-color: $text-secondary;
            color: $text-primary;
            padding-top: 2rem;
            width: 100%;
            margin: 0 auto;
            height: 180px;

            .center-footer{
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .container-logo-image {
                .img {
                    object-fit: contain;
                    width: 128px;
                    margin-left: 8px;
                }
            }
        }
    }
}

@media only screen and (min-width: 481px) and (max-width: 2556px) {
    .container-image-banner {
        top: unset !important;
    }
}