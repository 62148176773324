// =============== 
// Rasarivo
// ===============
@font-face {
    font-family: 'Rosarivo-Italic';
    src: local("Rosarivo-Italic"),
        url("../../../asset/fonts/Rosarivo-Italic.ttf")
}

@font-face {
    font-family: 'Rosarivo-Regular';
    src: local("Rosarivo-Regular"),
        url("../../../asset/fonts/Rosarivo-Regular.ttf")
}

.font-r-i {
    font-family: "Rosarivo-Italic";
}

.font-r-i {
    font-family: "Rosarivo-Regular";
}

// =============== 
// Montserrat
// ===============
@font-face {
    font-family: "Montserrat-Bold";
    src: local("Montserrat-Bold"),
        url('../../../asset/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: "Montserrat-MediumItalic";
    src: local("Montserrat-MediumItalic"),
        url('../../../asset/fonts/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"),
        url('../../../asset/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: bold;
}

.font-m-r {
    font-family: "Montserrat-Regular";
}

.font-m-b {
    font-family: "Montserrat-Bold";
}

.font-m-m {
    font-family: "Montserrat-MediumItalic";
}

// ===================
// Cormorant Garamond
// ===================
@font-face {
    font-family: "Garamond-Bold";
    src: local("CormorantGaramond-Bold"),
        url('./asset/font/CormorantGaramond-Bold.ttf') format('truetype');
}

@font-face {
    font-family: "Garamond-Regular";
    src: local("CormorantGaramond-Regular"),
        url('./asset/font/CormorantGaramond-Regular.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: "Script";
    src: local("CormorantGaramond-Regular"),
        url('./asset/font/edwardianscriptitc.ttf') format('truetype');
    font-weight: bold;
}

.font-g-b{
    font-family: "Garamond-Bold";
}

.font-g-r{
    font-family: "Garamond-Regular";
}

.font-s{
    font-family: "Script";
}