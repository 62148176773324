#ultimate-page{
    .container-body{
        background: $brown10;
        
        &.type-purple-10{
            background: $purple10;

            .container-header{
                .interval-date:hover{
                    cursor: pointer;
                    border: 2px solid $purple10 !important;
                    background-color: $purple10 !important;
                }
                .button-open{

                    &:hover{
                        cursor: pointer;
                        border: 2px solid $purple10 !important;
                        background-color: $purple10 !important;
                    }
                }
            }
            .container-banners{
                background-color: $purple10;

                .container-content-banner{
                    background-color: $purple10;
                }
            }
            .container-bride{
                .container-bride-name{
                    background-color: $purple10 !important;
                }
            }
            .container-wishes{
                input, textArea{
                    border: 1px solid $purple10;
                }
                .container-wishes-list{
                    div{
                        .content{
                            background-color: $purple10;
                        }
                    }
                }
            }
            .container-video{
                background-color: $purple10;
            }
            
            .menu-sticky{
                .container-menu{
                    background: $purple10;
                }
            }
        }
        &.type-purple-20{
            .container-banners{
                background-color: $purple20;
            }
            .container-bride{
                .title-bride{
                    color: $purple20;
                }
            }
            .container-event{
                color: $purple20;
                p{
                    color: $purple20;
                }
                .content-event{
                    border: 5px double $purple20;
                
                    .line{
                        border-top: 1px solid $purple20;
                        border-bottom: 1px solid $purple20;
                    }
                }
                .add-google a{
                    background: $purple20;
                    border: 2px solid $purple20;
                    

                    &:hover{
                        color: $purple20 !important;
                        border: 2px solid $purple20;
                    }
                }
            }
            .container-gallery{
                p{
                    color: $purple20;
                }

                .lg-custom-thumbnails{
                    grid-template-rows: repeat(30, 5vw);
                }
            }
            .container-quotes{
                .container-text{
                    color: $purple20;
                }
            }
            .container-gift{
                p{
                    color: $purple20;
                }
                .btn-gift{
                    button{
                        background: $purple20;
                        border: 2px solid $purple20;
                        
                        &:hover{
                            color: $purple20;
                            border: 2px solid $purple20;
                        }
                    }
                }
            }
            .container-wishes{
                p{
                    color: $purple20;
                }
                input, textArea, select{
                    color: $purple20;
                }
                label{
                    width: 100% !important;
                }

                .add-wishes{
                    button{
                        background: $purple20;
                        border: 2px solid $purple20;
                        
                        &:hover{
                            color: $purple20;
                            border: 2px solid $purple20;
                        }
                    }
                }

                .container-wishes-list{
                    div{
                        div{
                            svg{
                                color: $purple20;
                            }
                        }
                    }
                }
            }
            .container-text-video{
                color: $purple20;
            }
            .container-thankyou{
                .container-text-thankyou{
                    color: $purple20;
                }
            }
            .popup {
                color: $purple20;

                .btn-popup{
                    background: $purple20;

                    &:hover{
                        color: $purple20;
                        border: 2px solid $purple20 !important;
                    }
                }
                .close:hover, .close:focus {
                    color: $purple20;
                }
            }
            .popup_inner {
                .popup-img{
                    background-color: $purple20;
                }
            }
            #footer-section-ultimate {
                background-color: $purple20;
            }
        }
        &.type-purple-30{
            background: $purple30;

            .container-header{
                .interval-date:hover{
                    cursor: pointer;
                    border: 2px solid $purple30 !important;
                    background-color: $purple30 !important;
                }
                .button-open{

                    &:hover{
                        cursor: pointer;
                        border: 2px solid $purple30 !important;
                        background-color: $purple30 !important;
                    }
                }
            }
            .container-banners{
                background-color: $purple30;

                .container-content-banner{
                    background-color: $purple30;
                }
            }
            .container-bride{
                .container-bride-name{
                    background-color: $purple30 !important;
                }
            }
            .container-wishes{
                input, textArea{
                    border: 1px solid $purple30;
                }
                .container-wishes-list{
                    div{
                        .content{
                            background-color: $purple30;
                        }
                    }
                }
            }
            .container-video{
                background-color: $purple30;
            }
            
            .menu-sticky{
                .container-menu{
                    background: $purple30;
                }
            }
        }
        &.type-purple-40{
            .container-banners{
                background-color: $purple40;
            }
            .container-bride{
                .title-bride{
                    color: $purple40;
                }
            }
            .container-event{
                color: $purple40;
                p{
                    color: $purple40;
                }
                .content-event{
                    border: 5px double $purple40;
                
                    .line{
                        border-top: 1px solid $purple40;
                        border-bottom: 1px solid $purple40;
                    }
                }
                .add-google a{
                    background: $purple40;
                    border: 2px solid $purple40;
                    

                    &:hover{
                        color: $purple40 !important;
                        border: 2px solid $purple40;
                    }
                }
            }
            .container-gallery{
                p{
                    color: $purple40;
                }
                .lg-custom-thumbnails{
                    grid-template-rows: repeat(30, 5vw);
                }
            }
            .container-quotes{
                .container-text{
                    color: $purple40;
                }
            }
            .container-gift{
                p{
                    color: $purple40;
                }
                .btn-gift{
                    button{
                        background: $purple40;
                        border: 2px solid $purple40;
                        
                        &:hover{
                            color: $purple40;
                            border: 2px solid $purple40;
                        }
                    }
                }
            }
            .container-wishes{
                p{
                    color: $purple40;
                }
                input, textArea{
                    color: $purple40;
                }

                .add-wishes{
                    button{
                        background: $purple40;
                        border: 2px solid $purple40;
                        
                        &:hover{
                            color: $purple40;
                            border: 2px solid $purple40;
                        }
                    }
                }

                .container-wishes-list{
                    div{
                        div{
                            svg{
                                color: $purple40;
                            }
                        }
                    }
                }
            }
            .container-text-video{
                color: $purple40;
            }
            .container-thankyou{
                .container-text-thankyou{
                    color: $purple40;
                }
            }
            .popup {
                color: $purple40;

                .btn-popup{
                    background: $purple40;

                    &:hover{
                        color: $purple40;
                        border: 2px solid $purple40 !important;
                    }
                }
                .close:hover, .close:focus {
                    color: $purple40;
                }
            }
            .popup_inner {
                .popup-img{
                    background-color: $purple40;
                }
            }
            #footer-section-ultimate {
                background-color: $purple40;
            }
        }
        &.type-purple-50{
            background: $purple50;

            .container-header{
                .interval-date:hover{
                    cursor: pointer;
                    border: 2px solid $purple50 !important;
                    background-color: $purple50 !important;
                }
                .button-open{

                    &:hover{
                        cursor: pointer;
                        border: 2px solid $purple50 !important;
                        background-color: $purple50 !important;
                    }
                }
            }
            .container-banners{
                background-color: $purple50;

                .container-content-banner{
                    background-color: $purple50;
                }
            }
            .container-bride{
                .container-bride-name{
                    background-color: $purple50 !important;
                }
            }
            .container-wishes{
                input, textArea{
                    border: 1px solid $purple50;
                }
                .container-wishes-list{
                    div{
                        .content{
                            background-color: $purple50;
                        }
                    }
                }
            }
            .container-video{
                background-color: $purple50;
            }
            
            .menu-sticky{
                .container-menu{
                    background: $purple50;
                }
            }
        }
        &.type-purple-60{
            .container-banners{
                background-color: $purple60;
            }
            .container-bride{
                .title-bride{
                    color: $purple60;
                }
            }
            .container-event{
                color: $purple60;
                p{
                    color: $purple60;
                }
                .content-event{
                    border: 5px double $purple60;
                
                    .line{
                        border-top: 1px solid $purple60;
                        border-bottom: 1px solid $purple60;
                    }
                }
                .add-google a{
                    background: $purple60;
                    border: 2px solid $purple60;
                    

                    &:hover{
                        color: $purple60 !important;
                        border: 2px solid $purple60;
                    }
                }
            }
            .container-gallery{
                p{
                    color: $purple60;
                }
                .lg-custom-thumbnails{
                    grid-template-rows: repeat(30, 5vw);
                }
            }
            .container-quotes{
                .container-text{
                    color: $purple60;
                }
            }
            .container-gift{
                p{
                    color: $purple60;
                }
                .btn-gift{
                    button{
                        background: $purple60;
                        border: 2px solid $purple60;
                        
                        &:hover{
                            color: $purple60;
                            border: 2px solid $purple60;
                        }
                    }
                }
            }
            .container-wishes{
                p{
                    color: $purple60;
                }
                input, textArea{
                    color: $purple60;
                }

                .add-wishes{
                    button{
                        background: $purple60;
                        border: 2px solid $purple60;
                        
                        &:hover{
                            color: $purple60;
                            border: 2px solid $purple60;
                        }
                    }
                }

                .container-wishes-list{
                    div{
                        div{
                            svg{
                                color: $purple60;
                            }
                        }
                    }
                }
            }
            .container-text-video{
                color: $purple60;
            }
            .container-thankyou{
                .container-text-thankyou{
                    color: $purple60;
                }
            }
            .popup {
                color: $purple60;

                .btn-popup{
                    background: $purple60;

                    &:hover{
                        color: $purple60;
                        border: 2px solid $purple60 !important;
                    }
                }
                .close:hover, .close:focus {
                    color: $purple60;
                }
            }
            .popup_inner {
                .popup-img{
                    background-color: $purple60;
                }
            }
            #footer-section-ultimate {
                background-color: $purple60;
            }
        }

        &.type-blue-40{
            background: $blue40;

            .container-header{
                .interval-date:hover{
                    cursor: pointer;
                    border: 2px solid $blue40 !important;
                    background-color: $blue40 !important;
                }
                .button-open{

                    &:hover{
                        cursor: pointer;
                        border: 2px solid $blue40 !important;
                        background-color: $blue40 !important;
                    }
                }
            }
            .container-banners{
                background-color: $blue40;

                .container-content-banner{
                    color: $blue50;
                    background-color: $cream;
                }
            }
            .container-bride{
                .container-bride-name{
                    background-color: $blue40 !important;
                }
                .container-bride-photo{
                    background-color: $blue40;
                }
            }
            .container-wishes{
                input, textArea{
                    border: 1px solid $blue40;
                }
                .container-wishes-list{
                    div{
                        .content{
                            p{
                                color: $blue50 !important;
                            }
                            background-color: $grey10;
                        }
                    }
                }
            }
            .container-video{
                background-color: $blue40;
            }

            .our-story-wrapper{
                .title{
                    color: $blue50;
                }
            }
            
            .menu-sticky{
                .container-menu{
                    background: $blue40;
                }color: inherit !important;
            }
        }
        &.type-cream{
            .content{
                background-color: $cream;
            }
            .container-banners{
                background-color: $blue40;
            }
            .container-bride{
                .title-bride{
                    color: $blue50;
                }

            }
            .container-event{
                color: $blue50;
                p{
                    color: $blue50;
                }
                .content-event{
                    border: 5px double $blue50;
                
                    .line{
                        border-top: 1px solid $blue50;
                        border-bottom: 1px solid $blue50;
                    }
                }
                .add-google a{
                    color: #ffffff !important;
                    background: $blue40;
                    border: 2px solid $blue40;
                    

                    &:hover{
                        font-weight: 600;
                        color: $blue40 !important;
                        background: #ffffff;
                        border: 2px solid $blue40;
                    }
                }
            }
            .container-gallery{
                p{
                    color: $cream;
                }
                .lg-custom-thumbnails{
                    grid-template-rows: repeat(43, 5vw);
                }
            }
            .container-quotes{
                .container-text{
                    color: $blue50;
                }
            }
            .container-gift{
                p{
                    color: $blue50;
                }
                .btn-gift{
                    button.btn-gift-style{
                        color: #ffffff;
                        background: $blue40;
                        border: 2px solid $blue40;
                        
                        &:hover {
                            font-weight: 600;
                            color: $blue40;
                            background: #ffffff;
                            border: 2px solid $blue40;
                        }
                    }
                }
            }
            .container-wishes{
                p{
                    color: $blue50;
                }
                input, textArea{
                    color: $blue50;
                    background-color: white;
                }

                .add-wishes{
                    button.btn-wishes-style{
                        color: #ffffff;
                        background: $blue40;
                        border: 2px solid $blue40;
                        
                        &:hover{
                            font-weight: 600;
                            color: $blue40;
                            background: #ffffff;
                            border: 2px solid $blue40;
                        }
                    }
                }

                .container-wishes-list{
                    div{
                        div{
                            svg{
                                color: $blue50;
                            }
                        }
                    }
                }
            }
            .container-text-video{
                color: $blue50;
            }
            .container-thankyou{
                .container-text-thankyou{
                    color: $blue50;
                }
            }
            .popup {
                color: $blue50;

                .btn-popup{
                    color: #fff;
                    background: $blue50;

                    &:hover{
                        color: $blue50;
                        border: 2px solid $blue50 !important;
                    }
                }
                .close:hover, .close:focus {
                    color: $blue50;
                }
            }

            .popup_inner {
                background-color: $cream;
                .popup-img{
                    background-color: $cream;
                }
            }
            #footer-section-ultimate {
                background-color: $blue50;
            }
        }

        &.type-pink-20{
            background: $pink20;

            .container-header{
                .interval-date:hover{
                    cursor: pointer;
                    border: 2px solid $pink20 !important;
                    background-color: $pink20 !important;
                }
                .button-open{

                    &:hover{
                        cursor: pointer;
                        border: 2px solid $pink20 !important;
                        background-color: $pink20 !important;
                    }
                }
            }
            .container-banners{
                background-color: $pink20;

                .container-content-banner{
                    color: $blue50;
                    background-color: $cream;
                }
            }
            .container-bride{
                .container-bride-name{
                    background-color: $pink20 !important;
                }
                .container-bride-photo{
                    height: 800px;
                    background-color: $pink20;
                }
                // .bride-photo{
                //     position: absolute;
                //     object-fit: contain;
                //     width: 100%;
                //     height: 100%;
                // }
            }
            .container-wishes{
                input, textArea{
                    border: 1px solid $pink20;
                }
                .container-wishes-list{
                    div{
                        .content{
                            p{
                                color: $blue50 !important;
                            }
                            background-color: $grey10;
                        }
                    }
                }
            }
            .container-video{
                background-color: $pink20;
            }

            .our-story-wrapper{
                .title{
                    color: $grey30;
                }
            }
            
            .menu-sticky{
                .container-menu{
                    background: $pink20;
                    color: $grey30 !important;
                }
            }
        }
        &.type-grey-30{
            .content{
                background-color: $pink10;
            }
            .container-banners{
                background-color: #FFFFFF;
            }
            .container-bride{
                .title-bride{
                    color: $grey30;
                }
                .container-bride-name{
                    color: $grey30;
                }
                a{
                    color: $grey30 !important;
                }
            }
            .container-event{
                color: $grey30;
                p{
                    color: $grey30;
                }
                .content-event{
                    border: 5px double $grey30;
                
                    .line{
                        border-top: 1px solid $grey30;
                        border-bottom: 1px solid $grey30;
                    }
                }
                .add-google a{
                    color: #ffffff !important;
                    background: $grey25;
                    border: 2px solid $grey25;
                    
            
                    &:hover{
                        font-weight: 600;
                        color: $grey25 !important;
                        background: #ffffff;
                        border: 2px solid $grey25;
                    }
                }
            }
            .container-gallery{
                p{
                    color: $pink10;
                }
                .lg-custom-thumbnails{
                    grid-template-rows: repeat(43, 5vw);
                }
            }
            .container-quotes{
                .container-text{
                    color: $grey30;
                }
            }
            .container-gift{
                p{
                    color: $grey30;
                }
                .btn-gift{
                    button.btn-gift-style{
                        color: #ffffff;
                        background: $grey25;
                        border: 2px solid $grey25;
                        
                        &:hover {
                            font-weight: 600;
                            color: $grey25;
                            background: #ffffff;
                            border: 2px solid $grey25;
                        }
                    }
                }
            }
            .container-wishes{
                p{
                    color: $grey30;
                }
                input, textArea{
                    color: $grey30;
                    background-color: white;
                }
            
                .add-wishes{
                    button.btn-wishes-style{
                        color: #ffffff;
                        background: $grey25;
                        border: 2px solid $grey25;
                        
                        &:hover{
                            font-weight: 600;
                            color: $grey25;
                            background: #ffffff;
                            border: 2px solid $grey25;
                        }
                    }
                }
            
                .container-wishes-list{
                    div{
                        div{
                            svg{
                                color: $grey30;
                            }
                        }
                    }
                }
            }
            .container-text-video{
                color: $grey30;
            }
            .container-thankyou{
                .container-text-thankyou{
                    color: $grey30;
                }
            }
            .popup {
                color: $grey30;
            
                .btn-popup{
                    color: #fff;
                    background: $grey30;
            
                    &:hover{
                        color: $grey30;
                        border: 2px solid $grey30 !important;
                    }
                }
                .close:hover, .close:focus {
                    color: $grey30;
                }
            }
            
            .popup_inner {
                background-color: $pink10;
                .popup-img{
                    background-color: $pink10;
                }
            }
            #footer-section-ultimate {
                background-color: $grey25;
            }

            .music{
                background-color: $grey25;
                box-shadow: 2px 2px 3px #999;
            }
        }

        //Elvia
        &.type-purple-70 {
            background-color: $blue30;

            .container-header {
                .interval-date:hover {
                    cursor: pointer;
                    border: 2px solid $blue30  !important;
                    background-color: $blue30  !important;
                }

                .button-open {

                    &:hover {
                        cursor: pointer;
                        border: 2px solid $blue30  !important;
                        background-color: $blue30  !important;
                    }
                }
            }

            .container-banners {
                background-color: $blue30;
            }

            .container-bride {
                .title-bride {
                    color: $blue30;
                }
            }

            .container-event {
                color: $blue30;

                p {
                    color: $blue30;
                }

                .content-event {
                    border: 5px double $blue30;

                    .line {
                        border-top: 1px solid $blue30;
                        border-bottom: 1px solid $blue30;
                    }
                }

                .add-google a {
                    background: $blue30;
                    border: 2px solid $blue30;


                    &:hover {
                        color: $blue30  !important;
                        border: 2px solid $blue30;
                    }
                }
            }

            .container-gallery {
                p {
                    color: $blue30;
                }

                .lg-custom-thumbnails {
                    grid-template-rows: repeat(30, 5vw);
                }
            }

            .container-quotes {
                .container-text {
                    color: $blue30;
                }
            }

            .container-gift {
                p {
                    color: $blue30;
                }

                .btn-gift {
                    button {
                        background: $blue30;
                        border: 2px solid $blue30;

                        &:hover {
                            color: $blue30;
                            border: 2px solid $blue30;
                        }
                    }
                }
            }

            .our-story-wrapper {
                .title {
                    color: $blue50 !important;
                }
            }

            .container-wishes {
                p {
                    color: $blue30;
                }

                input,
                textArea {
                    color: $blue30;
                }

                .add-wishes {
                    button {
                        background: $blue30;
                        border: 2px solid $blue30;

                        &:hover {
                            color: $blue30;
                            border: 2px solid $blue30;
                        }
                    }
                }

                .container-wishes-list {
                    div {
                        div {
                            svg {
                                color: $blue30;
                            }
                        }
                    }
                }
            }

            .container-text-video {
                color: $blue30;
            }

            .container-thankyou {
                .container-text-thankyou {
                    color: $blue30;
                }
            }

            .popup {
                color: $blue30;

                .btn-popup {
                    background: $blue30;

                    &:hover {
                        color: $blue30;
                        border: 2px solid $blue30  !important;
                    }
                }

                .close:hover,
                .close:focus {
                    color: $blue30;
                }
            }

            .popup_inner {
                .popup-img {
                    background-color: $blue30;
                }
            }

            #footer-section-ultimate {
                background-color: $blue30;
            }
            .menu-sticky {
                .container-menu {
                    background: $blue30;
                    color: $blue50  !important;
                }
            }
        }
        &.type-cream-10 {
            .content {
                background-color: $cream10;
            }
        
            .container-banners {
                background-color: #FFFFFF;

                .container-content-banner {
                    background-color: $cream10;
                    color: $blue50;
                }
            }
        
            .container-bride {
                .title-bride {
                    color: $blue50;
                }
        
                .container-bride-name {
                    background-color: $purple70  !important;
                    color: $blue50;
                }
        
                a {
                    color: $blue50  !important;
                }

                .container-bride-photo {
                    background-color: $purple70;
                }
            }
        
            .container-event {
                color: $blue50;
        
                p {
                    color: $blue50;
                }
        
                .content-event {
                    border: 5px double $blue50;
        
                    .line {
                        border-top: 1px solid $blue50;
                        border-bottom: 1px solid $blue50;
                    }
                }
        
                .add-google a {
                    color: #ffffff !important;
                    background: $blue30;
                    border: 2px solid $blue30;
        
        
                    &:hover {
                        font-weight: 600;
                        color: $blue30  !important;
                        background: #ffffff;
                        border: 2px solid $blue30;
                    }
                }
            }
        
            .container-gallery {
                p {
                    color: $cream10;
                }
        
                .lg-custom-thumbnails {
                    grid-template-rows: repeat(43, 5vw);
                }
            }
        
            .container-quotes {
                .container-text {
                    color: $blue50;
                }
            }
        
            .container-gift {
                p {
                    color: $blue50;
                }
        
                .btn-gift {
                    button.btn-gift-style {
                        color: #ffffff;
                        background: $blue30;
                        border: 2px solid $blue30;
        
                        &:hover {
                            font-weight: 600;
                            color: $blue30;
                            background: #ffffff;
                            border: 2px solid $blue30;
                        }
                    }
                }
            }
        
            .container-wishes {
                p {
                    color: $blue50;
                }
        
                input,
                textArea {
                    color: $blue50;
                    background-color: white;
                    border: 1px solid $blue30;
                }
        
                .add-wishes {
                    button.btn-wishes-style {
                        color: #ffffff;
                        background: $blue30;
                        border: 2px solid $blue30;
        
                        &:hover {
                            font-weight: 600;
                            color: $blue30;
                            background: #ffffff;
                            border: 2px solid $blue30;
                        }
                    }
                }
        
                .container-wishes-list {
                    div {
                        .content {
                            background-color: $blue30;

                            p {
                                color: $blue50 !important;
                            }
                        }

                        div {
                            svg {
                                color: $blue50;
                            }
                        }
                    }
                }
            }
        
            .container-text-video {
                color: $blue50;
            }
        
            .container-thankyou {
                .container-text-thankyou {
                    color: $blue50;
                }
            }
        
            .popup {
                color: $blue50;
        
                .btn-popup {
                    color: #fff;
                    background: $blue50;
        
                    &:hover {
                        color: $blue50;
                        border: 2px solid $blue50  !important;
                    }
                }
        
                .close:hover,
                .close:focus {
                    color: $blue50;
                }
            }
        
            .popup_inner {
                background-color: $cream10;
        
                .popup-img {
                    background-color: $cream10;
                }
            }
        
            #footer-section-ultimate {
                background-color: $purple70;
            }
        
            .music {
                background-color: $blue30;
                box-shadow: 2px 2px 3px #999;
            }
        }

        //Jingga
        &.type-blue-60{
            background-color: $blue60;
            
                .container-header {
                    .interval-date:hover {
                        cursor: pointer;
                        border: 2px solid $blue60 !important;
                        background-color: $blue60 !important;
                    }
            
                    .button-open {
            
                        &:hover {
                            cursor: pointer;
                            border: 2px solid $blue60 !important;
                            background-color: $blue60 !important;
                        }
                    }
                }
            
                .container-banners {
                    background-color: $blue60;
                }
            
                .container-bride {
                    .title-bride {
                        color: $blue60;
                    }
                }
            
                .container-event {
                    color: $blue60;
            
                    p {
                        color: $blue60;
                    }
            
                    .content-event {
                        border: 5px double $blue60;
            
                        .line {
                            border-top: 1px solid $blue60;
                            border-bottom: 1px solid $blue60;
                        }
                    }
            
                    .add-google a {
                        background: $blue60;
                        border: 2px solid $blue60;
            
            
                        &:hover {
                            color: $blue60 !important;
                            border: 2px solid $blue60;
                        }
                    }
                }
            
                .container-gallery {
                    p {
                        color: $blue60;
                    }
            
                    .lg-custom-thumbnails {
                        grid-template-rows: repeat(30, 5vw);
                    }
                }
            
                .container-quotes {
                    .container-text {
                        color: $blue60;
                    }
                }
            
                .container-gift {
                    p {
                        color: $blue60;
                    }
            
                    .btn-gift {
                        button {
                            background: $blue60;
                            border: 2px solid $blue60;
            
                            &:hover {
                                color: $blue60;
                                border: 2px solid $blue60;
                            }
                        }
                    }
                }
            
                .our-story-wrapper {
                    .title {
                        color: $blue60 !important;
                    }
                }
            
                .container-wishes {
                    p {
                        color: $blue60;
                    }
            
                    input,
                    textArea {
                        color: $blue60;
                    }
            
                    .add-wishes {
                        button {
                            background: $blue60;
                            border: 2px solid $blue60;
            
                            &:hover {
                                color: $blue60;
                                border: 2px solid $blue60;
                            }
                        }
                    }
            
                    .container-wishes-list {
                        div {
                            div {
                                svg {
                                    color: $blue60;
                                }
                            }
                        }
                    }
                }
            
                .container-text-video {
                    color: $blue60;
                }
            
                .container-thankyou {
                    .container-text-thankyou {
                        color: $blue60;
                    }
                }
            
                .popup {
                    color: $blue60;
            
                    .btn-popup {
                        background: $blue60;
            
                        &:hover {
                            color: $blue60;
                            border: 2px solid $blue60 !important;
                        }
                    }
            
                    .close:hover,
                    .close:focus {
                        color: $blue60;
                    }
                }
            
                .popup_inner {
                    .popup-img {
                        background-color: $blue60;
                    }
                }
            
                #footer-section-ultimate {
                    background-color: $blue60;
                }
            
                .menu-sticky {
                    .container-menu {
                        background: $blue60;
                        color: #FFFFFF !important;
                    }
                }
        }
        &.type-grey-11{
            .content {
                background-color: $grey11;
            }
        
            .container-banners {
                background-color: #FFFFFF;
        
                .container-content-banner {
                    background-color: $grey11;
                    color: $blue60;
                }
            }
        
            .container-bride {
                .title-bride {
                    color: $blue60;
                }
        
                .container-bride-name {
                    background-color: $blue10 !important;
                    color: #FFFFFF;
                }
        
                a {
                    color: #FFFFFF !important;
                }
        
                .container-bride-photo {
                    background-color: $blue10;
                }
            }
        
            .container-event {
                color: $blue60;
        
                p {
                    color: $blue60;
                }
        
                .content-event {
                    border: 5px double $blue60;
        
                    .line {
                        border-top: 1px solid $blue60;
                        border-bottom: 1px solid $blue60;
                    }
                }
        
                .add-google a {
                    color: #ffffff !important;
                    background: $blue60;
                    border: 2px solid $blue60;
        
        
                    &:hover {
                        font-weight: 600;
                        color: $blue60 !important;
                        background: #ffffff;
                        border: 2px solid $blue60;
                    }
                }
            }
        
            .container-gallery {
                p {
                    color: $grey11;
                }
        
                .lg-custom-thumbnails {
                    grid-template-rows: repeat(43, 5vw);
                }
            }
        
            .container-quotes {
                .container-text {
                    color: $blue60;
                }
            }
        
            .container-gift {
                p {
                    color: $blue60;
                }
        
                .btn-gift {
                    button.btn-gift-style {
                        color: #ffffff;
                        background: $blue60;
                        border: 2px solid $blue60;
        
                        &:hover {
                            font-weight: 600;
                            color: $blue60;
                            background: #ffffff;
                            border: 2px solid $blue60;
                        }
                    }
                }
            }
        
            .container-wishes {
                p {
                    color: $blue60;
                }
        
                input,
                textArea {
                    color: $blue60;
                    background-color: white;
                    border: 1px solid $blue60;
                }
        
                .add-wishes {
                    button.btn-wishes-style {
                        color: #ffffff;
                        background: $blue60;
                        border: 2px solid $blue60;
        
                        &:hover {
                            font-weight: 600;
                            color: $blue60;
                            background: #ffffff;
                            border: 2px solid $blue60;
                        }
                    }
                }
        
                .container-wishes-list {
                    div {
                        .content {
                            background-color: $blue60;
        
                            p {
                                color: #fff !important;
                            }
                        }
        
                        div {
                            svg {
                                color: $blue60;
                            }
                        }
                    }
                }
            }
        
            .container-text-video {
                color: $blue60;
            }
        
            .container-thankyou {
                .container-text-thankyou {
                    color: $blue60;
                }
            }
        
            .popup {
                color: $blue60;
        
                .btn-popup {
                    color: #fff;
                    background: $blue60;
        
                    &:hover {
                        color: $blue60;
                        border: 2px solid $blue60 !important;
                    }
                }
        
                .close:hover,
                .close:focus {
                    color: $blue60;
                }
            }
        
            .popup_inner {
                background-color: $grey11;
                top: 30%;
                bottom: 30%;
        
                .popup-img {
                    background-color: $grey11;
                }
            }
        
            #footer-section-ultimate {
                background-color: $blue10;
            }
        
            .music {
                background-color: $blue60;
                box-shadow: 2px 2px 3px #999;
            }
        }

    // Leonardo
        &.type-brown-11{
            background-color: $leo2;
            
                .container-header {
                    .container-image{
                        .container-text{
                            color: $leo4 !important;
                        }

                        .container-date {
                            color: $leo4 !important;
                        }

                        .interval-date{
                            border: 2px solid $leo2 !important;
                            background-color: $leo2 !important;
                        }
                        .interval-date:hover {
                            cursor: pointer;
                            border: 2px solid $leo4 !important;
                            background-color: unset !important;
                        }
                
                        .button-open {
                            border: 2px solid $leo2 !important;
                            background-color: $leo2 !important;

                            &:hover {
                                cursor: pointer;
                                border: 2px solid $leo4 !important;
                                background-color: unset !important;
                            }
                        }

                    }
                }
            
                .container-banners {
                    background-color: $leo2;
                }
            
                .container-bride {
                    .title-bride {
                        color: $leo2;
                    }
                }
            
                .container-event {
                    color: $leo2;
            
                    p {
                        color: $leo2;
                    }
            
                    .content-event {
                        border: 5px double $leo2;
            
                        .line {
                            border-top: 1px solid $leo2;
                            border-bottom: 1px solid $leo2;
                        }
                    }
            
                    .add-google a {
                        background: $leo2;
                        border: 2px solid $leo2;
            
            
                        &:hover {
                            color: $leo2 !important;
                            border: 2px solid $leo2;
                            background-color: transparent !important;
                        }
                    }
                }
            
                .container-gallery {
                    p {
                        color: $leo2;
                    }
            
                    .lg-custom-thumbnails {
                        grid-template-rows: repeat(41, 5vw);
                    }
                }
            
                .container-quotes {
                    .container-text {
                        color: $leo2;
                    }
                }
            
                .container-gift {
                    p {
                        color: $leo2;
                    }
            
                    .btn-gift {
                        button {
                            background: $leo2;
                            border: 2px solid $leo2;
            
                            &:hover {
                                color: $leo2;
                                border: 2px solid $leo2;
                            }
                        }
                    }
                }
            
                .our-story-wrapper {
                    border-color: $leo2;

                    .title {
                        color: $leo2 !important;
                    }

                    .our-story-slider-wrapper .slick-arrow.slick-next:before, 
                    .our-story-slider-wrapper .slick-arrow.slick-prev:before,
                    .slick-dots li button::before{
                        color: $leo2;
                    }
                }
            
                .container-wishes {
                    p {
                        color: $leo2;
                    }
            
                    input,
                    textArea {
                        color: $leo2;
                    }
            
                    .add-wishes {
                        button {
                            background: $leo2;
                            border: 2px solid $leo2;
            
                            &:hover {
                                color: $leo2;
                                border: 2px solid $leo2;
                            }
                        }
                    }
            
                    .container-wishes-list {
                        div {
                            div {
                                svg {
                                    color: $leo2;
                                }
                            }
                        }
                    }
                }
            
                .container-text-video {
                    color: $leo2;
                }
            
                .container-thankyou {
                    .container-text-thankyou {
                        color: $leo2;
                    }
                }
            
                .popup {
                    color: $leo2;
            
                    .btn-popup {
                        background: $leo2;
            
                        &:hover {
                            color: $leo2;
                            border: 2px solid $leo2 !important;
                        }
                    }
            
                    .close:hover,
                    .close:focus {
                        color: $leo2;
                    }
                }
            
                .popup_inner {
                    .popup-img {
                        background-color: $leo2;
                    }
                }
            
                #footer-section-ultimate {
                    background-color: $leo2;
                }
            
                .menu-sticky {
                    .container-menu {
                        background: $leo4;
                        color: $leo2 !important;

                        .color-img{
                            filter: brightness(0) saturate(100%) invert(19%) sepia(5%) saturate(2635%) hue-rotate(314deg) brightness(96%) contrast(87%);
                        }
                    }
                }
        }
        &.type-cream-11{
            .content {
                background-color: $leo1;
            }
        
            .container-banners {
                background-color: $leo4;
        
                .container-content-banner {
                    background-color: $leo1;
                    color: $leo2;
                }

                .container-image2-banner{
                    filter: brightness(0) saturate(100%) invert(87%) sepia(19%) saturate(268%) hue-rotate(5deg) brightness(99%) contrast(103%);
                }
            }
        
            .container-bride {
                .title-bride {
                    color: $leo2;
                }
        
                .container-bride-name {
                    background-color: $leo4 !important;
                    color: $leo2 !important;
                }
        
                a {
                    color: $leo2 !important;
                }
        
                .container-bride-photo {
                    background-color: $leo3;
                }
            }
        
            .container-event {
                color: $leo2;
        
                p {
                    color: $leo2;
                }
        
                .content-event {
                    border: 5px double $leo2;
        
                    .line {
                        border-top: 1px solid $leo2;
                        border-bottom: 1px solid $leo2;
                    }
                }
        
                .add-google a {
                    color: $leo4 !important;
                    background: $leo2;
                    border: 2px solid $leo2;
        
        
                    &:hover {
                        font-weight: 600;
                        color: $leo2 !important;
                        background: $leo4;
                        border: 2px solid $leo2;
                    }
                }
            }
        
            .container-gallery {
                p {
                    color: $leo1;
                }
        
                .lg-custom-thumbnails {
                    grid-template-rows: repeat(41, 5vw);
                }
            }
        
            .container-quotes {
                .container-text {
                    color: $leo2;
                }

                .container-image-quotes {
                    img {
                        filter: brightness(0) saturate(100%) invert(87%) sepia(19%) saturate(268%) hue-rotate(5deg) brightness(99%) contrast(103%);
                    }
                }
            }
        
            .container-gift {
                p {
                    color: $leo2;
                }
        
                .btn-gift {
                    button.btn-gift-style {
                        color: $leo4;
                        background: $leo2;
                        border: 2px solid $leo2;
        
                        &:hover {
                            font-weight: 600;
                            color: $leo2;
                            background: transparent !important;
                            border: 2px solid $leo2;
                        }
                    }
                }
            }
        
            .container-wishes {
                p {
                    color: $leo2;
                }
        
                input,
                textArea {
                    color: $leo2;
                    background-color: white;
                    border: 1px solid $leo2;
                }
        
                .add-wishes {
                    button.btn-wishes-style {
                        color: $leo4;
                        background: $leo2;
                        border: 2px solid $leo2;
        
                        &:hover {
                            font-weight: 600;
                            color: $leo2;
                            border: 2px solid $leo2;
                            background-color: transparent !important;
                        }
                    }
                }
        
                .container-wishes-list {
                    div {
                        .content {
                            background-color: $leo2;
        
                            p {
                                color: #fff !important;
                            }
                        }
        
                        div {
                            svg {
                                color: $leo2;
                            }
                        }
                    }
                }
            }
        
            .container-text-video {
                color: $leo2;
            }
        
            .container-thankyou {
                .container-text-thankyou {
                    color: $leo2;
                }

                .container-image-thankyou{
                    img{
                        filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(0%) hue-rotate(274deg) brightness(102%) contrast(103%);
                    }
                }
            }
        
            .popup {
                color: $leo2;
        
                .btn-popup {
                    color: #fff;
                    background: $leo2;
        
                    &:hover {
                        color: $leo2;
                        border: 2px solid $leo2 !important;
                        background-color: transparent !important;
                    }
                }
        
                .close:hover,
                .close:focus {
                    color: $leo2;
                }
            }
        
            .popup_inner {
                background-color: $leo1;
                top: 30%;
                bottom: 30%;
        
                .popup-img {
                    background-color: $grey11;
                }
            }
        
            #footer-section-ultimate {
                color: $leo2 !important;
                background-color: $leo4 !important;
                
                img{
                    filter: brightness(0) saturate(100%) invert(19%) sepia(5%) saturate(2635%) hue-rotate(314deg) brightness(96%) contrast(87%);
                }
            }
        
            .music {
                background-color: $leo2;
                box-shadow: 2px 2px 3px #999;
            }
        }

        &.type-cream-dennis {
            background-color: $dennisMilkBrown;

            .container-header {
                .container-image {
                    .container-text {
                        color: $dennisMilkBrown !important;
                    }

                    .container-date {
                        color: $dennisMilkBrown !important;
                    }

                    .interval-date {
                        border: 2px solid $dennisMilkBrown !important;
                        background-color: $dennisMilkBrown !important;
                    }

                    .interval-date:hover {
                        cursor: pointer;
                        border: 2px solid $dennisCream !important;
                        background-color: unset !important;
                    }

                    .button-open {
                        border: 2px solid $dennisMilkBrown !important;
                        background-color: $dennisMilkBrown !important;

                        &:hover {
                            cursor: pointer;
                            border: 2px solid $dennisCream !important;
                            background-color: unset !important;
                        }
                    }

                }
            }

            .container-banners {
                background-color: $dennisMilkBrown;
            }

            .container-bride {
                .title-bride {
                    color: $dennisBlue;
                }
            }

            .container-event {
                color: $dennisBlue;

                p {
                    color: $dennisBlue;
                }

                .content-event {
                    border: 5px double $dennisBlue;

                    .line {
                        border-top: 1px solid $dennisBlue;
                        border-bottom: 1px solid $dennisBlue;
                    }
                }

                .add-google a {
                    background: $dennisMilkBrown;
                    border: 2px solid $dennisBlue;


                    &:hover {
                        color: $dennisBlue !important;
                        border: 2px solid $dennisBlue;
                        background-color: transparent !important;
                    }
                }
            }

            .container-gallery {
                p {
                    color: $dennisBlue;
                }

                .lg-custom-thumbnails {
                    grid-template-rows: repeat(41, 5vw);
                }
            }

            .container-quotes {
                .container-text {
                    color: $dennisBlue;
                }
            }

            .container-gift {
                p {
                    color: $dennisBlue;
                }

                .btn-gift {
                    button {
                        background: $dennisMilkBrown;
                        border: 2px solid $dennisMilkBrown;

                        &:hover {
                            color: $dennisBlue;
                            border: 2px solid $dennisMilkBrown;
                        }
                    }
                }
            }

            .our-story-wrapper {
                border-color: $dennisBlue;

                .title {
                    color: $dennisBlue !important;
                }

                .our-story-slider-wrapper .slick-arrow.slick-next:before,
                .our-story-slider-wrapper .slick-arrow.slick-prev:before,
                .slick-dots li button::before {
                    color: $dennisBlue;
                }
            }

            .container-wishes {
                p {
                    color: $dennisBlue;
                }

                input,
                textArea {
                    color: $dennisBlue;
                }

                .add-wishes {
                    button {
                        background: $dennisMilkBrown;
                        border: 2px solid $dennisBlue;

                        &:hover {
                            color: $dennisBlue;
                            border: 2px solid $dennisBlue;
                        }
                    }
                }

                .container-wishes-list {
                    div {
                        div {
                            svg {
                                color: $dennisBlue;
                            }
                        }
                    }
                }
            }

            .container-text-video {
                color: $dennisBlue;
            }

            .container-thankyou {
                .container-text-thankyou {
                    color: $dennisBlue;
                }
            }

            .popup {
                color: $dennisBlue;

                .btn-popup {
                    background: $dennisMilkBrown;

                    &:hover {
                        color: $dennisBlue;
                        border: 2px solid $dennisBlue !important;
                    }
                }

                .close:hover,
                .close:focus {
                    color: $dennisBlue;
                }
            }

            .popup_inner {
                .popup-img {
                    background-color: $dennisMilkBrown;
                }
            }

            #footer-section-ultimate {
                background-color: $dennisMilkBrown;
            }

            .menu-sticky {
                .container-menu {
                    background: $dennisCream;
                    color: $dennisBlue !important;

                    .color-img {
                        filter: brightness(0) saturate(100%) invert(19%) sepia(5%) saturate(2635%) hue-rotate(314deg) brightness(96%) contrast(87%);
                    }
                }
            }
        }

        &.type-blue-dennis {
            .content {
                background-color: $dennisCream;
            }

            .container-banners {
                background-color: $dennisCream;

                .container-content-banner {
                    background-color: $dennisMilkBrown;
                    color: $dennisBlue;
                }

                .container-image2-banner {
                    filter: brightness(0) saturate(100%) invert(84%) sepia(29%) saturate(276%) hue-rotate(348deg) brightness(91%) contrast(87%);
                }
            }

            .container-bride {
                .title-bride {
                    color: $dennisBlue;
                }

                .container-bride-name {
                    background-color: $dennisCream !important;
                    color: $dennisBlue !important;
                }

                a {
                    color: $dennisBlue !important;
                }

                .container-bride-photo {
                    background-color: $leo3;
                }
            }

            .container-event {
                color: $dennisBlue;

                p {
                    color: $dennisBlue;
                }

                .content-event {
                    border: 5px double $dennisMilkBrown;

                    .line {
                        border-top: 1px solid $dennisMilkBrown;
                        border-bottom: 1px solid $dennisMilkBrown;
                    }
                }

                .add-google a {
                    color: $dennisBlue !important;
                    background: $dennisMilkBrown;
                    border: 2px solid $dennisMilkBrown;


                    &:hover {
                        font-weight: 600;
                        color: $dennisBlue !important;
                        background: $dennisCream;
                        border: 2px solid $dennisBlue;
                    }
                }
            }

            .container-gallery {
                p {
                    color: $dennisBlue;
                }

                .lg-custom-thumbnails {
                    grid-template-rows: repeat(41, 5vw);
                }
            }

            .container-quotes {
                .container-text {
                    color: $dennisBlue;
                }

                .container-image-quotes {
                    img {
                        filter: brightness(0) saturate(100%) invert(92%) sepia(23%) saturate(221%) hue-rotate(321deg) brightness(104%) contrast(94%);
                    }
                }
            }

            .container-gift {
                p {
                    color: $dennisBlue;
                }

                .btn-gift {
                    button.btn-gift-style {
                        color: $dennisBlue;
                        background: $dennisMilkBrown;
                        border: 2px solid $dennisMilkBrown;

                        &:hover {
                            font-weight: 600;
                            color: $dennisBlue;
                            background: transparent !important;
                            border: 2px solid $dennisBlue;
                        }
                    }
                }
            }

            .container-wishes {
                p {
                    color: $dennisBlue;
                }

                input,
                textArea {
                    color: $dennisBlue;
                    background-color: white;
                    border: 1px solid $dennisBlue;
                }

                .add-wishes {
                    button.btn-wishes-style {
                        color: $dennisBlue;
                        background: $dennisMilkBrown;
                        border: 2px solid $dennisMilkBrown;

                        &:hover {
                            font-weight: 600;
                            color: $dennisBlue;
                            border: 2px solid $dennisBlue;
                            background-color: transparent !important;
                        }
                    }
                }

                .container-wishes-list {
                    div {
                        .content {
                            background-color: $dennisMilkBrown;

                            p {
                                color: #fff !important;
                            }
                        }

                        div {
                            svg {
                                color: $dennisBlue;
                            }
                        }
                    }
                }
            }

            .container-text-video {
                color: $dennisBlue;
            }

            .container-thankyou {
                .container-text-thankyou {
                    color: $dennisBlue;
                }

                .container-image-thankyou {
                    img {
                        filter: brightness(0) saturate(100%) invert(88%) sepia(10%) saturate(815%) hue-rotate(329deg) brightness(109%) contrast(84%);
                    }
                }
            }

            .popup {
                color: $dennisBlue;

                .btn-popup {
                    color: $dennisBlue;
                    background: $dennisGrey;
                    border: 2px solid $dennisGrey;

                    &:hover {
                        color: $dennisBlue;
                        border: 2px solid $dennisBlue;
                        background-color: transparent !important;
                    }
                }

                .close:hover,
                .close:focus {
                    color: $dennisBlue;
                }
            }

            .popup_inner {
                background-color: $dennisMilkBrown;
                top: 30%;
                bottom: 30%;

                .popup-img {
                    background-color: $grey11;
                }
            }

            #footer-section-ultimate {
                color: $dennisBlue !important;
                background-color: $dennisGrey !important;

                img {
                    filter: brightness(0) saturate(100%) invert(19%) sepia(5%) saturate(2635%) hue-rotate(314deg) brightness(96%) contrast(87%);
                }
            }

            .music {
                background-color: $dennisMilkBrown;
                box-shadow: 2px 2px 3px #999;
            }
        }
        //End of Type

        .content{
            max-width: 888px;
            margin: 0 auto;
            background-color: #fff;
        }

        .container-header{
            width: 100%;
            position: relative;
            margin: 0 auto;

            .container-image{
                position: relative;
                background-color: grey;
                height: 100vh;
                width: 100%;
                margin: 0 auto;

                
            }
            .image-header{
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                background-color: rgb(0, 0, 0);
                opacity: 0.5;
            }
            .container-text{
                position: absolute;
                display: inline-block;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                top: 20%;
                left: 0;
                right: 0;
                color: white;

                strong{
                    font-weight: bold;
                }
            }
            .text-header{
                width: 80%;
                margin: 0 auto;
            }
            .interval-date{
                color: white;
                background: none;
                border: 2px white;
                border-style: solid;
                border-radius: 10px;
                padding-top: 14px;
                width: 55px;
                height: 55px;
            }
            .interval-date:hover{
                cursor: pointer;
                border: 2px solid $brown20 !important;
                background-color: $brown20 !important;
            }
            .button-open{
                color: white;
                width: 200px;
                margin: 0 auto;
                background: none;
                border: 2px white;
                border-style: solid;
                border-radius: 10px;
                margin-top: 5vh;
                padding: 10px;

                &:hover{
                    cursor: pointer;
                    border: 2px solid $brown20 !important;
                    background-color: $brown20 !important;
                }
            }
            .container-date{
                position: absolute;
                display: block;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                bottom: 19vh;
                width: 80%;
                color: white;
            }
            .container-date-countdown{
                position: absolute;
                display: flex;
                justify-content: space-evenly;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                bottom: 5vh;
                width: 80%;
                color: white;
            }
        }

        .container-banners{
            width: 100%;
            position: relative;
            margin: 0 auto;
            padding-bottom: 100px;
            color: white;
            background-color: $brown20;


            .container-image-banner{
                position: relative;
                height: 70px;
                top: -60px;
                width: 100%;
                margin: 0 auto;
            }
            .image-banner{
                position: absolute;
                object-fit: fill;
                width: 100%;
                height: 100%;
            }
            .container-content-banner{
                width: 80%;
                margin: 0 auto;
                padding-top: 55px;
                color: white;
                background-color: $brown10;
            }
            .container-icon-banner{
                position: relative;
                width: 100%;
                height: 100px;
                margin: 0 auto;
            }
            .icon-banner{
                position: absolute;
                object-fit: contain;
                width: 50%;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
            }
            .text-banner{
                text-align: center;
                margin: 0 auto;
                width: 80%;
            }
            .container-image2-banner{
                position: relative;
                height: 70px;
                bottom: -25px;
                width: 100%;
                margin: 0 auto;
                z-index: 1;
            }
            .image3-banner{
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            .container-image3-banner{
                position: relative;
                height: 300px;
                width: 100%;
                margin: 0 auto;
            }
        }

        .container-bride{
            width: 80%;
            margin: 0 auto;
            padding-top: 55px;

            .container-icon-bride{
                position: relative;
                height: 0;
                padding-bottom: 50%;
                width: 100%;
                margin: 0 auto;
            }
            .icon-bride{
                position: absolute;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            .title-bride{
                text-align: center;
                padding-bottom: 40px;
                color: $brown20;
            }
            .container-bride-name{
                display: block;
                text-align: center;
                background-color: $brown10 !important;
                color: white;
                padding: 30px;
                border-radius: 10px;
            }
            .container-bride-photo{
                position: relative;
                height: 225px;
                width: 225px;
                border-radius: 50%;
                margin: 0 auto;
                background-color: $brown20;
            }
            .bride-photo{
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .container-event{
            text-align: center;
            color: $brown20;
            p{
                color: $brown20;
            }
            .content-event{
                width: 80%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 5px double $brown20;
                border-radius: 10px;
                padding: 10px 10px 0px 10px;
            
                .line{
                    width: 50%;
                    padding: 1px;
                    border-top: 1px solid $brown20;
                    border-bottom: 1px solid $brown20;
                }
                svg{
                    font-size: 30px;
                }
                p{
                    text-align: center;
                }
            }
            .add-google{
                width: 100%;
                margin: 0 auto;
            }
            .add-google a{
                color: #fff;
                background: $brown20;
                border: 2px solid $brown20;
                border-radius: 10px;
                padding: 10px;
                

                &:hover{
                    cursor: pointer;
                    color: $brown20;
                    border: 2px solid $brown20;
                    background-color: #fff;
                    transform: scale(1.1);
                    text-decoration: none;
                }
            }
        }

        .container-gallery{
            width: 100%;
            text-align: center;

            p{
                color: $brown20;
            }

            .lg-custom-thumbnails{
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(20, 5vw);
                grid-gap: 15px;

                a{
                    background: #ddd;
                }
                .gallery-img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: opacity 0.5s;
                    -webkit-transition: opacity 0.5s;
                    opacity: 1;
                }
                .gallery-img:hover{
                    opacity: 0.5;
                }
                
                .gallery-item--1 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 1;
                    grid-row-end: 5;
                }

                .gallery-item--2 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 1;
                    grid-row-end: 9;
                }

                .gallery-item--3 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 5;
                    grid-row-end: 15;
                }

                .gallery-item--4 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 9;
                    grid-row-end: 13;
                }

                .gallery-item--5 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 13;
                    grid-row-end: 23;
                }

                .gallery-item--6 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 15;
                    grid-row-end: 20;
                }

                .gallery-item--7 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 20;
                    grid-row-end: 30;
                }

                .gallery-item--8 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 23;
                    grid-row-end: 33;
                }

                .gallery-item--9 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 30;
                    grid-row-end: 35;
                }

                .gallery-item--10 {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 33;
                    grid-row-end: 43;
                }
                .gallery-item--10-el {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 33;
                    grid-row-end: 40;
                }

                .gallery-item--11 {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 35;
                    grid-row-end: 43;
                }
                .gallery-item--11-el {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 35;
                    grid-row-end: 40;
                }
            }
        }

        .container-slider-photo{
            position: relative;
            height: 500px;
            width: 100%;
            margin: 0 auto;

            .slide-photo{
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .container-quotes{
            width: 100%;
            position: relative;
            margin: 0 auto;

            .container-image-quotes{
                position: relative;
                height: 70px;
                top: -60px;
                width: 100%;
                margin: 0 auto;
            }
            .image-quotes{
                position: absolute;
                object-fit: fill;
                width: 100%;
                height: 100%;
            }
            .container-text{
                position: relative;
                top: -50px;
                width: 80%;
                margin: 0 auto;
                text-align: center;
                color: $brown20;
            }
        }

        .container-timeline{
            width: 80%;
            margin: 0 auto;

            .title-timeline {
                text-align: center;
                color: $brown20;
            }

            .ant-timeline-item-head-blue{
                color: $brown20;
                border-color: $brown20;
            }

            .container-timeline-content{
                color: $brown20;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rgba(0,0,0,.05);
                box-shadow: 0 2px 8px -2px rgba(0,0,0,.30);
            }
        }

        .container-gift{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 80%;
            margin: 0 auto;

            p{
                color: $brown20;
            }
            .btn-gift{
                width: 100%;
                margin: 0 auto;

                button.btn-gift-style{
                    color: #ffffff;
                    background: $brown20;
                    border: 2px solid $brown20;
                    border-radius: 10px;
                    padding: 10px;
                    width: 250px;
                    max-width: 80%;
                    
                    &:hover{
                        cursor: pointer;
                        color: $brown20;
                        border: 2px solid $brown20;
                        background-color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }

        .container-wishes{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            p{
                color: $brown20;
            }
            form{
                width: 80%;
            }
            input, textArea{
                width: 100%;
                padding: 10px;
                margin-bottom: 15px;
                border-radius: 5px;
                border: 1px solid $brown10;
                box-shadow: 0 1px 1px #ddd;
                color: $brown20;
            }
            input:focus-visible, textArea:focus-visible{
                outline: none;
            }

            .add-wishes{
                width: 100%;
                margin: 0 auto;

                button.btn-wishes-style{
                    color: #ffffff ;
                    background: $brown20;
                    border: 2px solid $brown20;
                    border-radius: 10px;
                    padding: 10px;
                    width: 250px;
                    max-width: 80%;
                    
                    &:hover{
                        cursor: pointer;
                        color: $brown20;
                        border: 2px solid $brown20;
                        background-color: #fff;
                        text-decoration: none;
                    }
                }
            }

            .container-wishes-list{
                width: 80%;
                height: 50vh;
                overflow-y: scroll;
                padding-right: 15px;

                div{
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: start;
                    padding-bottom: 15px;

                    .content{
                        width: 100%;
                        padding: 15px 15px 0 15px;
                        background-color: $brown10;
                        border-radius: 15px;
                        margin: unset;

                        p{
                            color: #fff !important;
                        }

                        .name{
                            font-weight: bold;
                        }
                    }

                    div{
                        display: flex;
                        flex-wrap: wrap;
                        text-align: start;
                        flex-direction: column;

                        svg{
                            margin-top: 15px;
                            margin-right: 15px;
                            color: $brown20;
                        }
                    }
                }
            }
        }

        .container-text-video{
            width: 80%;
            margin: 0 auto;
            text-align: center;
            color: $brown20;
        }
        .container-video{
            position: relative;
            width: 100%;
            height: 0;
            margin: 0 auto;
            padding-bottom: 50%;
            background-color: $brown10;
        }

        .video{
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .container-thankyou{
            width: 100%;
            margin: 0 auto;

            .container-text-thankyou{
                width: 80%;
                text-align: center;
                margin: 0 auto;
                color: $brown20;
            }
            .container-image-thankyou{
                position: relative;
                height: 70px;
                width: 100%;
                margin: 0 auto;
            }
            .image-thankyou{
                position: absolute;
                object-fit: fill;
                width: 100%;
                height: 100%;
            }
        }

        .menu-sticky{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 2;

            .container-menu{
                display: flex;
                justify-content: space-around;
                background: $brown10;
                align-items: center;
                // max-width: 888px;
                margin: 0 auto;

                div{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 5px;
                    cursor: pointer;
                }
                div img{
                    object-fit: cover;
                    width: 50px;
                    height: auto;
                    transition: transform 0.5s;
                    -webkit-transition: transform 0.5s;
                    transform: scale(1);
                }
                div img:hover{
                    transform: scale(1.1);
                }
            }
        }

        // Modal
        .popup {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: rgba(0,0,0, 0.5);
            z-index: 3;
            color: $brown20;

            .btn-popup{
                color: white;
                width: 200px;
                margin: 0 auto;
                background: $brown20;
                border: 2px solid white;
                border-radius: 10px;
                margin-top: 5vh;
                padding: 10px;

                &:hover{
                    cursor: pointer;
                    color: $brown20;
                    border: 2px solid $brown20 !important;
                    background-color: #fff !important;
                }
            }

            .close {
                color: #fff;
                float: right;
                font-size: 50px;
                font-weight: bold;
                padding: 20px;
            }
            .close:hover, .close:focus {
                color: $brown20;
                text-decoration: none;
                cursor: pointer;
            }
        }
        .popup_inner {
            position: absolute;
            left: 10%;
            right: 10%;
            top: 10%;
            bottom: 10%;
            margin: auto;
            background: white;
            z-index: 3;
            overflow-y: scroll;
            text-align: center;
            padding-top: 15px;
            border-radius: 10px;

            .popup-img{
                background-color: $brown20;
                width: 100%;
                height: 50vh;

                .popup-img-content{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .music{
            position:fixed;
            width:50px;
            height:50px;
            bottom:90px;
            right:20px;
            background-color:#03506F;
            border-radius:30px;
            text-align:center;
            box-shadow: 2px 2px 3px #999;
            z-index: 1;
        }

        .my-music{
            color: #FFF;
            margin-top:18px;
        }

        #footer-section-ultimate {
            background-color: $brown20;
            color: white;
            padding-bottom: 15vh;
            padding-top: 2rem;

            .container-logo-image{
                .img{
                    object-fit: contain;
                    width: 80px;
                    margin-left: 8px;
                }
            }
        }
    }

    @media only screen and (min-width: 481px) and (max-width: 2556px){
        .container-image-banner{
            top: unset !important;
        }
    }

    // @media only screen and (max-width: 600px){
    //     .bride-photo{
    //         position: absolute;
    //         object-fit: cover;
    //         width: 100%;
    //         height: 100%;
    //     }
    // }
}