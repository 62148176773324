#two-sided-images-component {
  margin: 50px 100px;

  .body-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;

    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;

    .item {
      flex: 1;

      .position-relative {
        position: relative;
      }

      .image-wrapper {
        height: 100%;

        .img {
          border-radius: 20px;
          object-fit: cover;
          height: 30rem;
        }
      }

      .text-wrapper {
        height: 30rem;

        background-color: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0 0 10px 0;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 20px 20px 20px 20px;

        position: absolute;
        top: 35px;
        left: -35px;
        width: 100%;

        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        .text, .author {
          text-align: center;
          color: #949494;
          font-family: "Quicksand", Sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.25em;
        }
      }
    }
  }
}

.half-circle {
  position: relative;

  &.type-upper {
    .body-wrapper {
      border-top-left-radius: 70%;
      border-top-right-radius: 70%;

      top: -4rem;
      left: 0;
    }
  }

  &.type-lower {
    .body-wrapper {
      border-bottom-left-radius: 70%;
      border-bottom-right-radius: 70%;

      bottom: -4rem;
      left: 0;
    }
  }

  .body-wrapper {
    position: absolute;
    height: 65px;
    width: 100%;
  }
}

#groom-side-by-side-component {
  margin: 125px 0;

  .body-wrapper {
    padding: 50px 0;

    .title-wrapper {
      margin: 25px 0;

      .title {
        text-align: center;
        color: #FFFFFF;
        font-family: "Artilla";
        font-size: 48px;
        font-weight: 400;
        line-height: 1em;
      }

      .description {
        margin: 15px 0;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25em;
      }
    }

    .groom-wrapper {
      display: flex;

      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;

      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      justify-content: space-around;
      align-items: center;

      .item {
        text-align: center;
        color: white;
        margin: 25px 0;
        font-weight: 400;

        img {
          border-radius: 50%;
          object-fit: cover;
          height: 265px;
          width: 265px;
          margin-bottom: 25px;
        }

        .nickname {
          font-size: 48px;
          line-height: 2em;
          color: white;
          font-family: "Artilla";
        }

        .name {
          font-size: 18px;
          line-height: 1em;
        }

        .divider-wrapper {
          padding: 25px 0;

          .divider-container {
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .divider {
              background: black;
              width: 15%;
              height: 1px;
            }
          }
        }

        .description, strong {
          font-size: 16px;
          line-height: 1.25em;
        }
      }
    }
  }
}

#full-image-component {
  .img {
    object-fit: cover;
    height: 100vh;
  }
}

#data-and-place-circle-component {
  margin: 125px 0;
  text-align: center;

  .title {
    color: #E0C06B;
    font-family: "Artilla";
    font-size: 48px;
    font-weight: 800;
    margin: 15px 0;
    letter-spacing: 7px;
    line-height: inherit;
  }

  .sub-title {
    color: #E0C06B;
    font-size: 16px;
    line-height: 1.25em;
  }

  .event-wrapper {
    margin: 20px;

    .event {
      background-color: #DB9D52;
      border-radius: 20px;
      color: white;
      padding: 50px;

      .title {
        color: white;
      }

      .date, .time {
        text-align: center;
        color: white;
        font-size: 18px;
      }
    }
  }
}

#footer-second-component {
  margin-top: 25px;
  text-align: center;

  .body-wrapper {
    padding: 50px 0;

    .img {
      object-fit: fill;
      height: 350px;
      width: 350px;
    }

    .social-media-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 15px;

      .item {
        padding: 0 20px;
      }
    }
  }
}