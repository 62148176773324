body, html, .container {
  -webkit-overflow-scrolling: touch;
  color: black !important;
}

a {
  color: inherit !important; /* blue colors for links too */
  text-decoration: inherit !important; /* no underline */
}

.default-title {
  font-size: 42px;
  font-weight: 600;
  margin: 40px 0;

  &.type-line {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $secondary-color;
      width: 15%;
      height: 4px;
    }
  }
}

.default-button {
  background: $secondary-color;
  color: white;

  background: linear-gradient(to left, $secondary-color 50%, $main-color 50%) right;
  background-size: 200%;
  transition: .5s ease-out;

  width: fit-content;
  padding: 4px 8px;
  border-radius: 5px;
  margin: 15px 0;

  &:hover {
    background-position: left;
  }
}